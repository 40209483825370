export const updateHrefs = (queryHref: string, targetHref: string) => {
  try {
    if (!document) return
    const elements = document.querySelectorAll(`[href="${queryHref}"]`)
    elements.forEach((element) => {
      element.setAttribute('href', targetHref)
    })
  } catch (e) {
    return null
  }
}
