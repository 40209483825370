import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RichText as RichTextType } from 'types/post'
import RichText, { BLOCKS } from '../RichText'
import Warning from './../../images/warning.png'
import useBrand from '../../hooks/useBrand'
import { interpolateString } from '../../utils/interpolateString'

interface InfoBoxProps {
  title: string
  text?: RichTextType
  body?: string
  variant?: string
  node?: React.ReactNode
  titleClass?: string
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  title,
  text,
  body,
  variant = 'warning',
  node,
  titleClass,
}) => {
  const brand = useBrand()
  const { classes: styles, cx } = useStyles()

  const infoIcon = {
    warning: (
      <img src={Warning} alt="Warning Icon" width="24px" height="24px" />
    ),
  }[variant || 'warning']

  return (
    <Box className={styles.infoBoxContainer}>
      {infoIcon}
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          variant="inherit"
          className={cx(
            styles.typographyBody,
            styles.infoBoxText,
            styles.bold,
            titleClass
          )}
        >
          {interpolateString(title, { ...brand })}
        </Typography>
        {text && (
          <RichText
            rawBody={text.raw}
            references={text.references}
            options={{
              props: {
                [BLOCKS.PARAGRAPH]: {
                  className: styles.textLines,
                },
              },
            }}
          />
        )}
        {body && (
          <Typography variant="inherit" className={cx(styles.typographyBody)}>
            {interpolateString(body, { ...brand })}
          </Typography>
        )}
        {node && node}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  infoBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    padding: '20px 32px 20px 20px',
    borderRadius: 12,
    background: theme.colors.amber[10],
  },
  typographyBody: {
    fontSize: 19,
    lineHeight: '24px',
    fontFamily: 'tt-talent',
    letterSpacing: 'normal',
    margin: 0,
  },
  infoBoxText: {
    color: theme.colors.amber[90],
  },
  bold: {
    fontWeight: 500,
  },
  textLines: {
    display: 'inline',
  },
}))
