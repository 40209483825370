import React from 'react'
import { Box, Divider } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { ContentfulEntry } from './ContentfulEntry'
import { useCritiquePage } from './CritiqueProvider'

interface CritiqueNextStepProps {
  items: any[]
}

export const CritiqueNextSteps: React.FC<CritiqueNextStepProps> = ({
  items,
}) => {
  const { classes, cx } = useCritiqueStyles()
  const { state } = useCritiquePage()

  const isV3 = state?.variant === 'v3'

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={cx(classes.nextStepsMainContainer, 'next-steps-container')}
    >
      {items?.length &&
        items.map((item, index) => (
          <Box
            display="flex"
            marginTop={'16px'}
            flexDirection="column"
            gap="20px"
            key={index}
          >
            <ContentfulEntry {...item} />
            {index < items.length - 1 && !isV3 && <Divider />}
          </Box>
        ))}
    </Box>
  )
}
