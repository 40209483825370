import React, { Dispatch, createContext, useContext, useReducer } from 'react'

export type CritiquePageState = {
  variant: string
}

const INITIAL_STATE = (variant: string) =>
  ({
    variant: variant || 'v1',
  } as CritiquePageState)

export type CritiquePageAction = {
  type: 'SET_VARIANT'
  value: string
}

export const CritiquePageContext = createContext<
  | {
      state: CritiquePageState
      dispatch: Dispatch<CritiquePageAction>
    }
  | undefined
>(undefined)

export const CritiquePageProvider = ({
  variant = 'v1',
  children,
}: {
  variant: string
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(
    (
      state: CritiquePageState,
      action: CritiquePageAction
    ): CritiquePageState => {
      switch (action.type) {
        case 'SET_VARIANT': {
          if (typeof action.value !== 'string') {
            throw new Error('Field must be a string')
          }

          return {
            ...state,
            variant: action.value,
          }
        }
        default:
          throw new Error(`Unknown action: ${action}`)
      }
    },
    INITIAL_STATE(variant)
  )

  return (
    <CritiquePageContext.Provider value={{ state, dispatch }}>
      {children}
    </CritiquePageContext.Provider>
  )
}

export const useCritiquePage = () => {
  const context = useContext(CritiquePageContext)

  if (!context) {
    throw new Error(
      'useCritiquePage must be used within a CritiquePageProvider'
    )
  }

  return context
}
