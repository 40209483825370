import React from 'react'
import { Box, Typography } from '@mui/material'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { useCritiqueStyles } from './styles'
import { interpolateString } from '../../utils/interpolateString'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'
import { useBrandedLogo } from './useBrandedLogo'

export const CritiqueTitleHeader: React.FC<{ title: string }> = ({ title }) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()
  const { t } = useTranslation()
  const brandLogo = useBrandedLogo()

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography
        variant="inherit"
        className={cx(classes.typographyHeaderTitle, classes.bold)}
      >
        {interpolateString(title, {
          ...brand,
        })}
      </Typography>
      <Box display="flex" flexDirection="row" gap="4px">
        <Typography variant="inherit" className={cx(classes.typographyPowered)}>
          {t(`${CritiqueTRef.headers}.poweredBy`)}
        </Typography>
        <img
          style={{
            position: 'relative',
            top: brandLogo.position.top,
            left: brandLogo.position.left,
          }}
          src={brandLogo.variants?.dark ?? brandLogo.url}
          alt={brandLogo.alt}
          height={brandLogo.size.height}
        />
      </Box>
    </Box>
  )
}
