import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { Template as BaseCritiqueTemplate } from '@talentinc/gatsby-theme-ecom/components/Critique/index'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { SEO } from '@talentinc/gatsby-theme-ecom/types/page'

export type CritiqueTemplateProps = PageProps<
  {
    critique: {
      __typename: string
      contentful_id: string
      name: string
      title: string
      slug: string
      rows: Array<{ __typename: string; contentful_id: string }>
      footer?: Array<{ __typename: string; contentful_id: string }>
      variant?: string
      seo: SEO | null
      createdAt: string
      updatedAt: string
    }
  } & PageDataContextType
>

const CritiqueTemplate: React.FC<CritiqueTemplateProps> = (props) => {
  const { critique, ...pageData } = props.data

  return (
    <Layout pageTitle={critique.title} pageData={pageData}>
      <BaseCritiqueTemplate
        rows={critique.rows}
        footer={critique?.footer}
        variant={critique?.variant}
      />
      <Footer className={['critique']} />
    </Layout>
  )
}

export const query = graphql`
  query CritiqueBySlugAndBrand($slug: String!, $brandName: String!) {
    critique: contentfulCritique(
      slug: { eq: $slug }
      node_locale: { eq: "en-GB" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...Critique
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CritiqueTemplate
