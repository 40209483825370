import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { RichText as RichTextType } from 'types/post'
import RichText, { BLOCKS } from '../RichText'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import useBrand from '../../hooks/useBrand'
import { interpolateString } from '../../utils/interpolateString'

interface SuccessStoryProps {
  items: SucessStoryItemProps[] | SucessStoryItemAPIProps[]
}

interface SucessStoryItemProps {
  title: string
  text: RichTextType
  centered?: boolean
  items: null
}

interface SucessStoryItemAPIProps {
  title: string
  subheader: string
  centered?: boolean
  items: {
    text: string
    url: string
  }[]
}

export const CritiqueSuccessStory: React.FC<SuccessStoryProps> = ({
  items,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="20px" margin="20px 0px">
      {items.map((item, index) => (
        <SuccessStoryItem key={index} {...item} />
      ))}
    </Box>
  )
}

export const SuccessStoryItem: React.FC<
  SucessStoryItemProps | SucessStoryItemAPIProps
> = (props) => {
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()

  return (
    <Box
      data-testid="section-success-stories"
      className={classes.successStoryItem}
      alignItems={props.centered ? 'center' : 'flex-start'}
    >
      {props.title || props?.items !== null ? (
        <Box display="flex" flexDirection="column" gap="12px">
          {props.title && (
            <Typography
              variant="inherit"
              className={cx(classes.typographySubhead, classes.bold, {
                [classes.centerText]: props.centered,
              })}
            >
              {interpolateString(props.title, { ...brand })}
            </Typography>
          )}
          {props?.items !== null && props?.subheader && (
            <Typography
              variant="inherit"
              className={cx(classes.typographyBody)}
            >
              {props.subheader}
            </Typography>
          )}
        </Box>
      ) : null}
      {props?.items === null && (
        <RichText
          rawBody={props.text.raw}
          references={props.text.references}
          options={{
            props: {
              [BLOCKS.PARAGRAPH]: {
                className: cx(classes.typographyBody, {
                  [classes.centerText]: props.centered,
                }),
              },
            },
          }}
          replace={{
            critiquer: { email: activeCritique?.critiquer?.critiquer_email },
          }}
        />
      )}
      {props?.items !== null && (
        <Box display="flex" flexDirection="column" gap="16px">
          {props.items.map(({ text, url }, index) => (
            <Typography
              variant="inherit"
              component="a"
              href={url}
              target="_blank"
              key={index}
              className={cx(
                classes.link,
                classes.linkSmall,
                classes.typographyBody
              )}
            >
              {text}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  )
}
