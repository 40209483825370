import React from 'react'
import { Box } from '@mui/material'
import { useCritiqueStyles } from './styles'

interface OutlinedContainerProps {
  children: React.ReactNode
}

export const OutlinedContainer: React.FC<OutlinedContainerProps> = ({
  children,
}) => {
  const { classes } = useCritiqueStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      className={classes.outlinedContainer}
    >
      {children}
    </Box>
  )
}
