import { useMemo } from 'react'
import useBrand from '../../hooks/useBrand'
import { useSessionQuery } from '../../components/Providers/UserSession'

export const useBrandedLogo = () => {
  const brand = useBrand()
  const session = useSessionQuery()

  const Logo = useMemo(() => {
    const defaultLogo = brand.logo?.file?.url
    const partnerLogo = session?.data?.partnerCoBrand?.logo

    if (!partnerLogo)
      return {
        url: defaultLogo,
        variants: {
          dark: brand.logo?.file?.url,
          light: brand.whiteLogo?.file?.url,
        },
        alt: brand.display_name,
        position: {
          top: -5,
          left: 4,
        },
        size: {
          width: 1860 * 0.07,
          height: 485 * 0.07,
        },
      }

    return {
      url: partnerLogo,
      alt: session?.data?.partnerCoBrand.name || brand.display_name,
      position: {
        top: -5,
        left: 2,
      },
      size: {
        width: 578 * 0.35,
        height: 80 * 0.35,
      },
    }
  }, [session, brand])

  return Logo
}
