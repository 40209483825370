import React from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'

interface CritiqueChipListProps {
  header: string
  items: CritiqueChipProps[]
}

export interface CritiqueChipProps {
  text: string
  variant?: string
}

export const CritiqueChipList: React.FC<CritiqueChipListProps> = ({
  header,
  items,
}) => {
  const { classes, cx } = useCritiqueStyles()

  if (!items.length) return null

  return (
    <Box className={classes.chipListContainer}>
      <Typography variant="inherit" className={cx(classes.typographyBody)}>
        {header}
      </Typography>
      <Box className={classes.chipList}>
        {items.slice(0, 7).map(({ text, variant }, i) => {
          return <CritiqueChip key={i} text={text} variant={variant} />
        })}
      </Box>
    </Box>
  )
}

const CritiqueChip: React.FC<CritiqueChipProps> = ({
  text,
  variant = 'neutral',
}) => {
  const { classes, cx } = useCritiqueStyles()
  return (
    <Box
      className={cx({
        [classes.chip]: true,
        [classes.typographyCaption]: true,
        [classes.blueChip]: variant === 'active',
        [classes.neutralChip]: variant === 'neutral',
      })}
    >
      {text}
    </Box>
  )
}
