import React, { useContext, useMemo } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import useBrand from '../../hooks/useBrand'

// import CritiqueBG from './../../images/critique-bg-texture.png'
import { capitalize } from '../../utils/string'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
// import CritiqueBG from './../../images/critique-bg-texture.png'

const CRITIQUEBG_IMAGE_CDN =
  'https://images.ctfassets.net/7thvzrs93dvf/5taaFmJuPtaDXt7MbJTfRc/f57e63b116da1fc5f3e94173096570fa/critique-bg-second.png'

export const CTAFooter = () => {
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()

  const {
    active: [, , { isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const capitalizedBrandFlagship = capitalize(brand.flagshipProduct)

  const text = useMemo(() => {
    if (isNearlyPerfect) {
      return {
        header: <>Expand your opportunities with interview coaching services</>,
        body: (
          <>
            While the job hunt is never easy, a career coach in your corner will
            make all of the difference. From interview preparation to long-term
            career strategy, our career coach services will help you succeed.
          </>
        ),
        btn: <>Find a coach</>,
      }
    }
    return {
      header: (
        <>
          Land a job faster with the help of our {brand.flagshipProduct} writers
        </>
      ),
      body: (
        <>
          Our professional {brand.flagshipProduct}-writing service has helped
          over 1 million professionals land more interviews and get hired
          faster.
        </>
      ),
      btn: <>View {capitalizedBrandFlagship} Services</>,
    }
  }, [isNearlyPerfect, capitalizedBrandFlagship, brand.flagshipProduct])

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.CTABannerWrapper}
    >
      <Container className={classes.CTABannerContainer}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.CTABannerContent}
          style={{
            overflow: 'hidden',
            backgroundImage: `url(${CRITIQUEBG_IMAGE_CDN})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            alignItems="center"
            className={classes.centerText}
          >
            <Typography
              variant="inherit"
              className={cx(
                classes.typographyHeader2Desktop,
                classes.typographySmallMd
              )}
            >
              {text.header}
            </Typography>
            <Typography
              variant="inherit"
              className={cx(classes.typographyBody)}
            >
              {text.body}
            </Typography>
            <Button
              className={cx(classes.mainButton, classes.mainButtonSmallMd)}
              href={`/${brand.flagshipProduct}-writing`}
            >
              {text.btn}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
