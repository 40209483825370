import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { interpolateString } from '../../utils/interpolateString'
import useBrand from '../../hooks/useBrand'
import { useCritiqueStyles } from './styles'

interface CritiqueInfoProps {
  items?: ICritiqueInfoItems[]
  html?: string
}

export interface ICritiqueInfoItems {
  text?: string
  variant?: string
  color?: string
  url?: string
  tooltip?: string
  html?: string
}

const TooltipWrapper: React.FC<{
  condition: boolean
  text?: string
  children: React.ReactNode
}> = ({ condition, text = '', children }) => {
  return condition ? (
    <Tooltip
      title={
        <Typography
          variant="inherit"
          className="critique-text-line"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      }
      placement="top"
      classes={{
        tooltip: 'critique-tooltip',
        arrow: 'critique-tooltip-arrow',
      }}
      arrow
    >
      {children as React.ReactElement}
    </Tooltip>
  ) : (
    children
  )
}

export const CritiqueTextLines: React.FC<
  CritiqueInfoProps & { classNames?: string[] }
> = ({ items, html, classNames }) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box className={cx(classes.textLines, classNames)}>
        {items &&
          items.map(({ text, variant, url, color, tooltip, html }, i) => {
            if (html) {
              return (
                <Typography
                  key={i}
                  variant="inherit"
                  dangerouslySetInnerHTML={{ __html: html }}
                  className={cx(classes.typographyBody, classes.textLineText)}
                />
              )
            }
            return (
              <TooltipWrapper key={i} condition={!!tooltip} text={tooltip}>
                <Typography
                  variant="inherit"
                  component={url ? 'a' : 'span'}
                  href={url}
                  key={i}
                  className={cx(classes.typographyBody, classes.textLineText, {
                    [classes.bolder]: variant === 'bold',
                    [classes.link]: !!url || !!tooltip,
                    [classes.cursorHelp]: !!tooltip,
                    [classes.infoBoxText]: !url,
                    [classes.orange]: color === 'orange',
                    [classes.typographySubhead]: variant === 'title',
                    [classes.textTitle]: variant === 'title',
                  })}
                  target={url ? '_blank' : undefined}
                >
                  {interpolateString(text || '', { ...brand })}
                </Typography>
              </TooltipWrapper>
            )
          })}
        {html && (
          <Typography
            variant="inherit"
            className="critique-text-line"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
      </Box>
    </Box>
  )
}
