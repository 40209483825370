import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useCritiqueStyles } from './styles'
import { CritiqueTRef } from './Translation'
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale)

interface IndustriesChartDataProps {
  dataset: {
    name: string
    percent_of_overall: string
  }[]
}

const COLOR_SERIES = ['#0F3871', '#C3E5FE']

export const IndustriesChart: React.FC<IndustriesChartDataProps> = ({
  dataset,
}) => {
  const { t } = useTranslation()
  const { classes } = useCritiqueStyles()
  const parsedData = useMemo(() => {
    const serializedData = dataset.reduce(
      (acc, { name, percent_of_overall }, i) => {
        const intValue = Math.round(Number(percent_of_overall))

        acc.data.push(intValue.toString())
        acc.labels.push(`${intValue}% — ${name}`)
        acc.backgroundColor.push(COLOR_SERIES[i % COLOR_SERIES.length])
        acc.sum -= parseFloat(percent_of_overall)

        return acc
      },
      {
        data: [] as string[],
        labels: [] as string[],
        backgroundColor: [] as string[],
        sum: 100,
      }
    )

    const sumSerialFixed = serializedData.sum.toFixed(0)

    serializedData.data.push(sumSerialFixed)
    serializedData.labels.push(
      `${sumSerialFixed}% — ${t(
        `${CritiqueTRef.widgets}.applicantATS.otherIndustries`
      )}`
    )
    serializedData.backgroundColor.push('#E7EAF4')

    return serializedData
  }, [t, dataset])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="24px"
    >
      <Typography variant="inherit" className={classes.typographySubhead}>
        {t(`${CritiqueTRef.widgets}.applicantATS.whereApplicantFit`)}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap="40px"
        className={classes.chartContainer}
      >
        <Box width={150} height="160px">
          <Doughnut
            options={{
              cutout: '70%',
              spacing: 1,
              hover: {
                mode: 'index',
              },
              maintainAspectRatio: false,
              layout: {
                autoPadding: false,
              },
              animation: {
                animateRotate: true,
                animateScale: true,
                easing: 'easeInOutQuart',
              },
              datasets: {
                doughnut: {
                  borderRadius: 5,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
                title: { display: false },
              },
            }}
            data={{
              labels: parsedData.labels,
              datasets: [
                {
                  data: parsedData.data,
                  backgroundColor: parsedData.backgroundColor,
                },
              ],
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="12px">
          {parsedData.data.map((_, i) => (
            <DataLabel
              key={i}
              label={parsedData.labels[i]}
              color={parsedData.backgroundColor[i]}
              isLast={i === parsedData.data.length - 1}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const DataLabel: React.FC<{
  label: string
  color: string
  isLast: boolean
}> = ({ label, color, isLast }) => {
  const { classes, cx } = useCritiqueStyles()
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
      <Box width={20} height={20} borderRadius="20px" bgcolor={color} />
      <Typography
        variant="inherit"
        className={cx({
          [classes.typographyBody]: true,
          [classes.neutral]: isLast,
        })}
      >
        {label}
      </Typography>
    </Box>
  )
}
