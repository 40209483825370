import React from 'react'
import { Box } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { useBrandedLogo } from './useBrandedLogo'

export const CritiqueBannerLogo = () => {
  const { classes } = useCritiqueStyles()
  const brandLogo = useBrandedLogo()

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="4px"
      className={classes.bannerLogo}
    >
      <img
        style={{
          position: 'relative',
          top: brandLogo.position.top,
          left: brandLogo.position.left,
          maxWidth: 300,
        }}
        src={brandLogo.url}
        alt={brandLogo.alt}
      />
    </Box>
  )
}
