import { RefObject, useEffect, useState } from 'react'

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

/**
 * useIntersectionObserver is a custom hook that detects visibility of a component
 * on the viewport using the [IntersectionObserver API](https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API)
 * natively present in the browser
 * @param elementRef DOM element ref
 * @param options Optional intersection observer options
 * with `freezeOnceVisible` to only catch the first appearance
 * @returns `true` if the element intersects the viewport, `false` otherwise (default)
 */

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = true,
  }: Args
): boolean {
  const [isVisible, setIsVisible] = useState(false)

  const frozen = isVisible && freezeOnceVisible
  const thresholdDep = Array.isArray(threshold)
    ? threshold.toString()
    : threshold

  const handleIntersection = ([entry]: IntersectionObserverEntry[]): void => {
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const node = elementRef?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(
      handleIntersection,
      observerParams
    )

    observer.observe(node)

    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current, thresholdDep, root, rootMargin, frozen])

  return isVisible
}

export default useIntersectionObserver
