import React from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { CritiqueTextLines } from './TextLines'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'
import useBrand from '../../hooks/useBrand'

interface TrackingExplanationProps {
  header: string
  variant?: string
}

const lineItems = [
  { text: 'line1' },
  { text: 'line2', tooltip: 'atsTooltip' },
  { text: 'line3' },
  { text: 'line4', variant: 'bold' },
  { text: 'line5' },
  { text: 'line6', variant: 'bold' },
  { text: 'line7', html: true },
]

export const CritiqueTrackingExplanation: React.FC<
  TrackingExplanationProps
> = ({ header, variant = 'v1' }) => {
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()
  const { classes, cx } = useCritiqueStyles()

  const isV2 = variant === 'v2'

  const translatedLineItems = lineItems.map(
    ({ text, tooltip, variant, html }) => ({
      [html ? 'html' : 'text']: t(
        `${CritiqueTRef.text}.hiringManagers.${text}`,
        { flagshipProduct }
      ),
      tooltip: tooltip
        ? t(`${CritiqueTRef.text}.hiringManagers.${tooltip}`, {
            flagshipProduct,
          })
        : undefined,
      variant,
    })
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      id="critique-section-skills"
      className={cx(classes.critiqueSection, classes.sectionScrollMargin)}
    >
      <Typography
        variant="inherit"
        className={cx(
          classes[isV2 ? 'typographyHeader3Desktop' : 'typographyHeader2'],
          classes.bold
        )}
      >
        {header}
      </Typography>
      {isV2 ? (
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography variant="inherit" className={classes.typographyBody}>
            Earlier in my review, I introduced you to Applicant Tracking
            Systems, or ATS. Before I wrap up, I want to tell you a little more
            about them, in the context of your resume.
          </Typography>
          <Typography variant="inherit" className={classes.typographyBody}>
            To get past the resume screeners, you should tailor your resume to
            each job you apply to. With my clients, I spend time to understand
            the kinds of roles they want, and identify the keywords and hard
            skills that are important for them to include.
          </Typography>
          <Typography variant="inherit" className={classes.typographyBody}>
            I did a quick automated run through of your resume on an ATS to give
            you a sense of a few skills and industries it pulled out (ignore
            those that aren't necessarily skills, this was just what the system
            pulled out).
          </Typography>
        </Box>
      ) : (
        <CritiqueTextLines
          classNames={[classes.textLinesSpaced]}
          items={translatedLineItems}
        />
      )}
    </Box>
  )
}
