import React from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import { interpolateString } from '../../utils/interpolateString'

interface CritiqueLetterHeaderProps {
  header: {
    normal: string
    italic: string
  }
  description: string
}

export const CritiqueRawDataText: React.FC<CritiqueLetterHeaderProps> = ({
  header,
  description,
}) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box className={classes.rawDataHeaderContainer}>
        <Typography
          variant="inherit"
          className={cx(classes.typographyRawDataHeader, classes.bold)}
        >
          {interpolateString(header.normal, { ...brand })}
        </Typography>{' '}
        <Typography
          variant="inherit"
          className={cx(
            classes.typographyRawDataHeader,
            classes.italic,
            classes.bold,
            classes.spacedLeft
          )}
        >
          {interpolateString(header.italic, { ...brand })}
        </Typography>
      </Box>
      {description && (
        <Typography
          variant="inherit"
          className={classes.typographyRawDataDescription}
        >
          {interpolateString(description, { ...brand })}
        </Typography>
      )}
    </Box>
  )
}
