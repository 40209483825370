import React, { useContext, useMemo } from 'react'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { ContentString } from './content'
import { CritiqueTitleHeader } from './TitleHeader'
import { CritiqueLetterHeader } from './LetterHeader'
import { CritiqueTextColumnData } from './TextColumn'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import { CritiqueNextSteps } from './NextSteps'
import { SuccessStoryItem } from './SuccessStory'
import { CritiqueRawDataText } from './RawDataText'
import { CritiqueInfoGrid, mapInfoGrid } from './InfoGrid'
import { InfoBox } from '../InfoBox/InfoBox'
import { CritiqueTrackingExplanation } from './TrackingExplanation'
import { OutlinedContainer } from './Containers'
import { IndustriesChart } from './IndustriesChart'
import { CritiqueSkillSection, mapSkillsList } from './SkillSection'
import { CritiqueRecsNextSteps, mapBulletPoints } from './RecsNextSteps'
import { CritiqueExportSignature } from './ExpertSignature'
import { useToggleParent } from '@talentinc/gatsby-theme-ecom/hooks/useToggleParent'
import useBrand from '../../hooks/useBrand'
import { useCritiqueStyles } from './styles'
import { capitalize } from '../../utils/string'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'

const DISABLE_INFOBOX_DESCRIBE_CARREER = true

const InfoboxNodeWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { classes } = useCritiqueStyles()

  return (
    <Box>
      <Typography variant="inherit" className={classes.typographyBody}>
        {children}
      </Typography>
    </Box>
  )
}

export const CritiqueFullSections: React.FC<{
  hideIntro?: boolean
  mapTitles?: boolean
  variant?: string
}> = ({ hideIntro = false, mapTitles = false, variant = 'v1' }) => {
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()
  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const isV2 = variant === 'v2'

  const hiddenSections =
    hideIntro && isPerfect && isV2 ? ['outro', 'intro'] : ['outro']

  if (isV2) {
    hiddenSections.push('digital-readiness')
    hiddenSections.push('employment-history')
  }

  return (
    <>
      {!!!hideIntro && (
        <CritiqueTitleHeader
          title={t(`${CritiqueTRef.headers}.main`, {
            brandProduct: capitalize(flagshipProduct),
          })}
        />
      )}
      {!!!hideIntro && (
        <CritiqueLetterHeader
          reviewer={{
            name: activeCritique?.critiquer
              ? `${activeCritique?.critiquer?.critiquer_first_name} ${activeCritique?.critiquer?.critiquer_last_name}`
              : undefined,
            email: activeCritique?.critiquer?.critiquer_email,
          }}
          date={activeCritique.created_at}
        />
      )}
      <CritiqueTextColumnData
        sections={(activeCritique?.full_sections || []).filter(
          ({ sections }: { sections: string }) =>
            !hiddenSections.includes(sections)
        )}
        data={{
          resume_file_size: activeCritique?.ats_data?.resume_file_size || 0,
          resume_file_percentile:
            activeCritique.ats_data?.resume_size_percentile || 0,
        }}
        mapTitles={mapTitles}
        variant={variant}
      />
    </>
  )
}

export const CritiqueAtsSection: React.FC<{ variant?: string }> = ({
  variant = 'v1',
}) => {
  const { t } = useTranslation()
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()

  const isV2 = variant === 'v2'

  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const blankRef = useToggleParent(isPerfect, isV2)

  const employmentHistorySection = useMemo(() => {
    const employmentSection = activeCritique?.full_sections?.find(
      (section: { sections: string }) =>
        section.sections === 'employment-history'
    )
    return employmentSection?.Bodies.map(
      (body: { voice_body: string }) => body.voice_body
    ).join(' ')
  }, [activeCritique])

  if (Object.keys(activeCritique).length <= 0) return null

  if (!isPerfect) return <div ref={blankRef} style={{ display: 'none' }} />

  return (
    <>
      <div ref={blankRef} style={{ display: 'none' }} />
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        id="critique-section-ats"
        className={classes.sectionScrollMargin}
      >
        {isV2 ? (
          <Typography
            variant="inherit"
            className={cx(classes.typographyHeader3Desktop, classes.bold)}
          >
            {t(`${CritiqueTRef.sectionHeaders}.atsScan`)}
          </Typography>
        ) : (
          <CritiqueRawDataText
            header={{
              normal: t(`${CritiqueTRef.headers}.rawData.normal`),
              italic: t(`${CritiqueTRef.headers}.rawData.italic`, brand),
            }}
            description={activeCritique?.ats_data?.overall_description}
          />
        )}
        {isV2 && (
          <Box display="flex" flexDirection="column" gap="16px">
            {employmentHistorySection ? (
              <Typography
                variant="inherit"
                className={cx('critique-text-line', classes.typographyBody)}
                dangerouslySetInnerHTML={{
                  __html: employmentHistorySection,
                }}
              />
            ) : (
              <>
                <Typography
                  variant="inherit"
                  className={classes.typographyBody}
                >
                  Your resume is the first impression you make on a potential
                  employer. It’s important that it accurately reflects your
                  experience and skills.
                </Typography>
                <Typography
                  variant="inherit"
                  className={classes.typographyBody}
                >
                  While your template highlights your work experience, it can
                  likely be better optimized to get past applicant tracking
                  systems (ATS). 93% of all Hiring Managers use resume scanning
                  software called{' '}
                  <Tooltip
                    arrow
                    placement="top"
                    classes={{
                      tooltip: 'critique-tooltip',
                      arrow: 'critique-tooltip-arrow',
                    }}
                    title={
                      <Typography
                        variant="inherit"
                        className="critique-text-line"
                        dangerouslySetInnerHTML={{
                          __html: `<strong>What is an ATS?</strong><br/>An applicant tracking system (ATS) is a program used by employers to screen applicants based on the contents of their resume before passing viable candidates on to the hiring manager.`,
                        }}
                      />
                    }
                  >
                    <Typography
                      variant="inherit"
                      component="span"
                      className={cx(classes.link, classes.cursorHelp)}
                    >
                      {'Applicant Tracking Systems (ATS)'}
                    </Typography>
                  </Tooltip>{' '}
                  to filter candidates from the application pool.
                </Typography>
              </>
            )}
            <Typography variant="inherit" className={classes.typographyBody}>
              I did a quick automated run through of your resume on an ATS to
              give you a sense of how its read by the system.
            </Typography>
          </Box>
        )}
        <CritiqueInfoGrid listItems={mapInfoGrid(activeCritique.ats_data, t)} />
        <InfoBox
          title={t(`${CritiqueTRef.widgets}.infoBox.doesLookLikeYou.title`)}
          // body={ContentString.doesLookLikeYou.body}
          node={
            <InfoboxNodeWrapper>
              {`${t(
                `${CritiqueTRef.widgets}.infoBox.doesLookLikeYou.part1`,
                brand
              )} `}
              <Tooltip
                arrow
                placement="top"
                classes={{
                  tooltip: 'critique-tooltip',
                  arrow: 'critique-tooltip-arrow',
                }}
                title={
                  <Typography
                    variant="inherit"
                    className="critique-text-line"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `${CritiqueTRef.text}.hiringManagers.atsTooltip`
                      ),
                    }}
                  />
                }
              >
                <Typography
                  variant="inherit"
                  component="span"
                  className={cx(classes.link, classes.cursorHelp)}
                >
                  {t(
                    `${CritiqueTRef.widgets}.infoBox.doesLookLikeYou.linkText`
                  )}
                </Typography>
              </Tooltip>{' '}
              {t(`${CritiqueTRef.widgets}.infoBox.doesLookLikeYou.part2`)}
            </InfoboxNodeWrapper>
          }
        />
      </Box>
    </>
  )
}

export const CritiqueWriterSignatureSection: React.FC<{
  name: string
  variant?: string
}> = ({ name, variant }) => {
  const { classes } = useCritiqueStyles()
  const {
    active: [, activeCritique, { isNearlyPerfect, isTroubled }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const brand = useBrand()
  const { t } = useTranslation()

  const upperCasedBrand =
    (brand?.flagshipProduct || '').charAt(0).toUpperCase() +
    (brand?.flagshipProduct || '').slice(1)

  if (Object.keys(activeCritique).length <= 0) return null

  const isV1 = variant === 'v1'
  // const isV2 = variant === 'v2'

  if (isV1 && (isNearlyPerfect || isTroubled)) return null

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Box display="flex" flexDirection="column" gap="24px">
        <Typography className={classes.typographyBody} variant="inherit">
          {t(`${CritiqueTRef.headers}.outro`)}
        </Typography>
        <Typography className={classes.typographyBody} variant="inherit">
          {t(`${CritiqueTRef.headers}.outroThanks`)},
        </Typography>
      </Box>
      <CritiqueExportSignature
        critiquer={{
          name: `${activeCritique?.critiquer?.critiquer_first_name} ${activeCritique?.critiquer?.critiquer_last_name}`,
          email: activeCritique?.critiquer?.critiquer_email,
          role: `${upperCasedBrand} Expert, ${brand.display_name}`,
          // signature: activeCritique?.critiquer?.critiquer_signature,
          picture: activeCritique?.critiquer?.critique_image,
        }}
      />
    </Box>
  )
}

export const CritiqueRawDataSection: React.FC<{ variant?: string }> = ({
  variant = 'v1',
}) => {
  const { t } = useTranslation()
  const { classes } = useCritiqueStyles()
  const brand = useBrand()

  const isV2 = variant === 'v2'

  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const blankRef = useToggleParent(isPerfect, isV2)

  if (Object.keys(activeCritique).length <= 0) return null

  const upperCasedBrand = capitalize(brand?.flagshipProduct || '')

  if (!isPerfect) return <div ref={blankRef} style={{ display: 'none' }} />

  return (
    <>
      <div ref={blankRef} style={{ display: 'none' }} />
      <Box display="flex" flexDirection="column" gap={isV2 ? '24px' : '32px'}>
        <CritiqueTrackingExplanation
          header={
            isV2
              ? ContentString.trackingExplanation.header2
              : t(`${CritiqueTRef.sectionHeaders}.whatSystemThinkApplicant`)
          }
          variant={variant}
        />
        {!!Object.values(
          (activeCritique?.ats_data?.grouped_competencies as Record<
            string,
            any
          >) || {}
        )?.[0]?.length && (
          <OutlinedContainer>
            {(activeCritique?.ats_data?.industries as unknown[])?.length > 0 ? (
              <IndustriesChart dataset={activeCritique.ats_data.industries} />
            ) : null}
            {!DISABLE_INFOBOX_DESCRIBE_CARREER &&
            !brand.isTopCvFr &&
            !brand.isZipJob ? (
              <InfoBox
                title={t(
                  `${CritiqueTRef.widgets}.infoBox.doesDescribeCareer.title`
                )}
                node={
                  <InfoboxNodeWrapper>
                    {t(
                      `${CritiqueTRef.widgets}.infoBox.doesDescribeCareer.part1`
                    )}{' '}
                    <a
                      className={classes.link}
                      href="../success-stories"
                      target="_blank"
                    >
                      {t(
                        `${CritiqueTRef.widgets}.infoBox.doesDescribeCareer.linkText`
                      )}
                    </a>{' '}
                    {t(
                      `${CritiqueTRef.widgets}.infoBox.doesDescribeCareer.part2`
                    )}
                  </InfoboxNodeWrapper>
                }
              />
            ) : null}
            {!DISABLE_INFOBOX_DESCRIBE_CARREER && <Divider />}
            <CritiqueSkillSection
              items={mapSkillsList(
                activeCritique.ats_data.grouped_competencies
              )}
            />
            <InfoBox
              title={t(`${CritiqueTRef.widgets}.infoBox.skillsetConvey.title`)}
              node={
                <InfoboxNodeWrapper>
                  {t(`${CritiqueTRef.widgets}.infoBox.skillsetConvey.part1`)}{' '}
                  <a
                    className={classes.link}
                    href={`mailto:${activeCritique.critiquer.critiquer_email}?subject=${upperCasedBrand} Questions`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(
                      `${CritiqueTRef.widgets}.infoBox.skillsetConvey.linkText`
                    )}
                  </a>
                  {t(`${CritiqueTRef.widgets}.infoBox.skillsetConvey.part2`)}
                </InfoboxNodeWrapper>
              }
            />
          </OutlinedContainer>
        )}
      </Box>
    </>
  )
}

export const CritiqueNextStepsSection: React.FC<{ variant?: string }> = ({
  variant = 'v1',
}) => {
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  if (Object.keys(activeCritique).length <= 0) return null

  return (
    <CritiqueRecsNextSteps
      heading={activeCritique?.full_summary?.headings}
      items={mapBulletPoints(activeCritique?.full_summary?.full_bullet_points)}
      variant={variant}
    />
  )
}

export const CritiqueNextStepsList: React.FC<{ items: any[] }> = ({
  items,
}) => {
  return <CritiqueNextSteps items={items} />
}

export const CritiqueSidebarLinks: React.FC<{
  title: string
  text: any
  centered?: boolean
}> = ({ title, text, centered = false }) => {
  return (
    <SuccessStoryItem
      title={title}
      text={text}
      items={null}
      centered={centered}
    />
  )
}
