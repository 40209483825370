import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useCritiqueStyles } from './styles'
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { KeyboardArrowUp, Clear } from '@mui/icons-material'
import CheckMarkSVG from '@talentinc/gatsby-theme-ecom/images/checkmarkblue.inline.svg'
import { ProductPackageDetail } from 'types/productPageV2'
import { useSKU } from '../../hooks/useSKUs'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import RichText from '../../components/RichText'
import { BLOCKS } from '@contentful/rich-text-types'
import useBrand from '../../hooks/useBrand'
import { capitalize } from '../../utils/string'
import { useWindowSize } from '../../hooks/useWindowDimension'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '../../utils/mixpanel'
import useTidyLink from '../../hooks/useTidyLink'
import { MixpanelEventTypes, MixpanelEvents } from '../../types/mixpanel'

export interface PriceColumnsProps {
  name: string
  text: {
    text: string
  }
  view: string[]
  productPackageRows: ProductPackageDetail[]
}

const ENABLE_PRICES_DISPLAY = false

const MIXPANEL_EVENTS_BY_PACKAGE: Record<string, MixpanelEvents> = {
  '7r8Et1rPEFpDDqkxCqpKbG': MixpanelEvents.LearnMoreStarterClick,
  '4kHI8JTH2AlZG9d9VPU3th': MixpanelEvents.LearnMorePremiumClick,
  '3ks77QEgOtVkQmY1LRrooB': MixpanelEvents.LearnMoreUltimateClick,
}

const PriceColumn: React.FC<ProductPackageDetail> = ({
  contentful_id,
  title,
  description,
  productPackage,
  sellingPoints,
}) => {
  const brand = useBrand()
  const [showPrice, setShowPrice] = useState(false)
  const { classes, cx } = useCritiqueStyles()
  const { width } = useWindowSize()
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const tidyTheLink = useTidyLink()
  const critiqueEventTrackOn = useMpEventTrackOn(
    MixpanelEventTypes.CritiqueEvent
  )

  const isMobile = width ? width <= 768 : true

  const toggleShowPrice = () => {
    setShowPrice(!showPrice)
  }

  const handlebarsContext = useHandlebarsContext()

  const sku = useSKU(
    productPackage.items,
    productPackage.planCode,
    null,
    productPackage.sort
  )

  return (
    <Box
      className={cx(
        classes.outlinedContainer,
        classes.tight,
        classes.centerText,
        classes.priceColumn
      )}
      display="flex"
      flexDirection="column"
      gap="16px"
      onClick={toggleShowPrice}
    >
      <Box position="relative" display="flex" flexDirection="column" gap="0px">
        <Box className={classes.pricingArrowContainer}>
          <Typography
            variant="inherit"
            className={cx(classes.pricingArrowToggle, {
              [classes.pricingArrowToggleOpen]: showPrice,
            })}
          >
            <KeyboardArrowUp />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <Box
            position="relative"
            display="flex"
            gap="4px"
            className={classes.priceHeaderText}
          >
            {ENABLE_PRICES_DISPLAY && (
              <>
                {sku && (
                  <Typography
                    variant="inherit"
                    className={classes.priceHeaderValue}
                  >
                    {sku.formattedAmount}
                  </Typography>
                )}
                <Typography
                  variant="inherit"
                  className={cx(classes.priceSeparator)}
                >
                  {'-'}
                </Typography>
              </>
            )}
            <Typography
              variant="inherit"
              className={cx(classes.typographyBody, classes.priceName, {
                [classes.typographyHeader3Desktop]: !ENABLE_PRICES_DISPLAY,
              })}
            >
              {title}
            </Typography>
          </Box>
          <Box className={classes.priceDescriptionContainer}>
            <Typography
              variant="inherit"
              className={cx(
                classes.priceDescription,
                classes.typographyCaption,
                classes.neutral
              )}
            >
              {insertHandlebarsTemplate(description, handlebarsContext)}
            </Typography>
          </Box>
        </Box>
        <Collapse in={showPrice || !isMobile}>
          <Box
            className={cx(classes.priceColumnContent, {
              [classes.hidePrice]: !showPrice,
            })}
          >
            <Button
              data-testid={`cta-${title.toLowerCase()}-learn-more`}
              variant="contained"
              color="primary"
              fullWidth
              className={cx(classes.mainButtonSmall, classes.fullWidthButton)}
              href={`../${brand.flagshipProduct}-writing`}
              onAuxClick={(e) => {
                handleMpCTAClickTracking(
                  e.currentTarget.textContent,
                  tidyTheLink(
                    `/${brand.flagshipProduct?.toLowerCase()}-writing`
                  ),
                  {
                    eventName: MIXPANEL_EVENTS_BY_PACKAGE[contentful_id],
                    trackingData: {
                      event_type: MixpanelEventTypes.CritiqueEvent,
                    },
                    trackOn: critiqueEventTrackOn,
                  }
                )
                e.stopPropagation()
              }}
              onClick={(e) => {
                handleMpCTAClickTracking(
                  e.currentTarget.textContent,
                  tidyTheLink(
                    `/${brand.flagshipProduct?.toLowerCase()}-writing`
                  ),
                  {
                    eventName: MIXPANEL_EVENTS_BY_PACKAGE[contentful_id],
                    trackingData: {
                      event_type: MixpanelEventTypes.CritiqueEvent,
                    },
                    trackOn: critiqueEventTrackOn,
                  }
                )
                e.stopPropagation()
              }}
            >
              Learn More
            </Button>
            <Box display="flex" flexDirection="column" gap="12px">
              {sellingPoints.map((point) => (
                <ListItem
                  className={cx(
                    classes.sellingPoint,
                    classes.sellingPointSpace
                  )}
                  disableGutters
                  key={point.contentful_id}
                >
                  <ListItemIcon className={classes.listItemIcon2}>
                    {point.variant === 'disabled' ? (
                      <Clear
                        fontSize="small"
                        className={cx(classes.icon, classes.neutral)}
                      />
                    ) : (
                      <CheckMarkSVG className={classes.icon} />
                    )}
                  </ListItemIcon>
                  {point.sellingPointBody && (
                    <RichText
                      rawBody={point.sellingPointBody.raw}
                      references={point.sellingPointBody.references}
                      options={{
                        props: {
                          [BLOCKS.PARAGRAPH]: {
                            variant: 'inherit',
                            className: cx(classes.typographyCaption, {
                              [classes.neutral]: point.variant === 'disabled',
                            }),
                            style: {
                              margin: 0,
                            },
                          },
                        },
                      }}
                    />
                  )}
                </ListItem>
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

const PriceDisclaimer: React.FC = () => {
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4px"
      className={cx(classes.priceDisclaimer, classes.centerText)}
    >
      <Typography variant="inherit" className={cx(classes.typographyCaption)}>
        60-Day Interview Guarantee
      </Typography>
      <Typography
        variant="inherit"
        className={cx(classes.typographyCaption, classes.neutral)}
      >
        If you don’t get called for an interview in 60-days, your rewrite is on
        us.
      </Typography>
    </Box>
  )
}

export const CritiquePriceColumns: React.FC<PriceColumnsProps> = ({
  name,
  text,
  productPackageRows,
  view,
}) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()

  const {
    active: [, , { isPerfect, isTroubled, isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const inView = useIntersectionObserver(mainRef, {
    threshold: 0.1,
    freezeOnceVisible: false,
  })

  useEffect(() => {
    const containerElement =
      document.getElementsByClassName('critique-page v2')?.[0]
    if (containerElement) {
      containerElement.classList[inView ? 'add' : 'remove']('price-visible')
    }
  }, [inView])

  const capitalizedBrandFlagship = capitalize(brand.flagshipProduct)

  const shouldRender = useMemo(() => {
    if (!view) return false
    if (view?.includes('normal') && isPerfect) return true
    if (view?.includes('troubled') && isTroubled) return true
    if (view?.includes('nearlyperfect') && isNearlyPerfect) return true
  }, [isPerfect, isTroubled, isNearlyPerfect, view])

  if (!shouldRender) return null

  return (
    <div ref={mainRef}>
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        id="critique-section-pricing"
        className={classes.sectionScrollMargin}
      >
        <Typography
          variant="inherit"
          className={cx(classes.typographyHeader3Desktop, classes.bold)}
        >
          {name}
        </Typography>
        <Typography
          variant="inherit"
          className={classes.typographyBody}
          dangerouslySetInnerHTML={{ __html: text.text }}
        />
        <Box display="flex" className={cx(classes.singlePriceColumns)}>
          {productPackageRows.map((productPackageDetail, index) => (
            <PriceColumn key={index} {...productPackageDetail} />
          ))}
        </Box>
        <Box className={classes.getExpertButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={cx(classes.mainButtonSmall, classes.fullWidthButton)}
            href={`/${brand.flagshipProduct}-writing`}
            target="_blank"
          >
            Get An Expert To Write Your {capitalizedBrandFlagship}
          </Button>
        </Box>
        {isNearlyPerfect || <PriceDisclaimer />}
      </Box>
    </div>
  )
}
