import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { SkeletonLoader } from './SkeletonLoader'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'
import useSiteMetadata from '../../hooks/useSiteMetadata'

interface CritiqueLetterHeaderProps {
  reviewer: {
    name?: string
    email: string
  }
  date: string
}

export const CritiqueLetterHeader: React.FC<CritiqueLetterHeaderProps> = ({
  reviewer,
  date,
}) => {
  const { locale } = useSiteMetadata()
  const { t } = useTranslation()
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="32px" width="100%">
      <Box display="flex" flexDirection="column" gap="16px">
        <Divider />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="wrap"
          gap="5px"
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" gap="4px">
              <SkeletonLoader
                skeleton={{
                  width: 110,
                  height: 30,
                }}
                condition={!!reviewer?.name}
              >
                <Typography
                  variant="inherit"
                  className={cx(
                    classes.typographyBody,
                    classes.typographyToggleSmall
                  )}
                >
                  {reviewer?.name}
                </Typography>
              </SkeletonLoader>
              <SkeletonLoader
                skeleton={{
                  width: 200,
                  height: 30,
                }}
                condition={!!reviewer?.email}
              >
                <Typography
                  variant="inherit"
                  className={cx(
                    classes.typographyBody,
                    classes.typographyToggleSmall,
                    classes.neutral
                  )}
                >
                  {`<${reviewer?.email}>`}
                </Typography>
              </SkeletonLoader>
            </Box>
            <Box
              sx={{
                position: 'relative',
              }}
              style={{
                top: !!reviewer?.email ? 0 : -6,
              }}
            >
              <Typography
                variant="inherit"
                className={cx(
                  classes.typographyBody,
                  classes.typographyToggleSmall,
                  classes.neutral
                )}
              >
                {t(`${CritiqueTRef.headers}.sender.tome`)}
              </Typography>
            </Box>
          </Box>
          <SkeletonLoader
            skeleton={{ width: 130, height: 27 }}
            condition={!!reviewer?.email}
          >
            <Typography
              variant="inherit"
              className={cx(
                classes.typographyBody,
                classes.typographyToggleSmall,
                classes.neutral
              )}
            >
              {new Date(date).toLocaleDateString(locale ?? 'en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          </SkeletonLoader>
        </Box>
        <Divider />
      </Box>
    </Box>
  )
}
