import React, { useContext, useMemo } from 'react'
import { Box, Button, List, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { RichText as RichTextType } from 'types/post'
import RichText, { BLOCKS } from '../RichText'
import { GradientScale } from './GradientScale'
import { SkeletonLoader } from './SkeletonLoader'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'
import useBrand from '../../hooks/useBrand'
import { capitalize } from '../../utils/string'
import { useBrandedLogo } from './useBrandedLogo'
import { InfoBox } from '@talentinc/gatsby-theme-ecom/components/InfoBox/InfoBox'
import { ContentString } from './content'

interface TextColumnProps {
  name?: string
  title?: string
  text?: RichTextType
  body?: null
  html?: null
  variant?: string
}

interface TextColumnSimpleProps {
  name?: string
  title?: string
  text?: null
  body?: string
  html?: string
  variant?: string
}

interface CritiqueFullSection {
  sections: string
  titles?: string
  Bodies: {
    voice_body: string
  }[]
}

const percentileToText = (percentile: number) => {
  switch (true) {
    case percentile < 10:
      return 'muchSmaller'
    case percentile < 25:
      return 'smallerThan'
    case percentile < 65:
      return 'sameAs'
    case percentile < 90:
      return 'largerThan'
    default:
      return 'muchLargerThan'
  }
}

const TITLES_MAP = ['formatting', 'language', 'atsScan']

const BrowsePackagesSection = () => {
  const { classes, cx } = useCritiqueStyles()
  const brandLogo = useBrandedLogo()

  const scrollToSection = () => {
    const sectionElement = document.getElementById(`critique-section-pricing`)
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.browsePackagesSection}
      gap="18px"
    >
      <Button
        data-testid="cta-browse-our-packages"
        onClick={scrollToSection}
        className={cx(classes.fullWidthButton, classes.linkSmall)}
      >
        Browse Our Packages
      </Button>
      <Box display="flex" flexDirection="row" gap="4px" justifyContent="center">
        <Typography
          variant="inherit"
          className={cx(classes.typographyCaption, classes.neutral)}
        >
          Powered by
        </Typography>
        <img
          style={{
            position: 'relative',
            top: brandLogo.position.top,
            left: brandLogo.position.left,
          }}
          src={brandLogo.url}
          alt={brandLogo.alt}
          width={brandLogo.size.width}
          height={brandLogo.size.height}
        />
      </Box>
    </Box>
  )
}

const ImperfectCritiqueIntro: React.FC = () => {
  const { classes, cx } = useCritiqueStyles()
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()
  const {
    active: [, , { isPerfect, isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  if (isPerfect) return null

  const text = isNearlyPerfect
    ? t(`${CritiqueTRef.headers}.nearperfect`, {
        brandProductCapitalized: capitalize(flagshipProduct),
      })
    : t(`${CritiqueTRef.headers}.troubled`, { flagshipProduct })

  return (
    <Box mt={'20px'}>
      <Typography
        variant="inherit"
        className={cx(classes.typographyHeader2, classes.bold)}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Box>
  )
}

export const CritiqueTextColumnData: React.FC<{
  sections: CritiqueFullSection[]
  data: any
  mapTitles?: boolean
  variant?: string
}> = ({ sections, data, mapTitles = false, variant = 'v1' }) => {
  const {
    active: [, , { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const isV2 = variant === 'v2'
  const { t } = useTranslation()
  const { flagshipProduct } = useBrand()
  const { isTopCV, isTopCVuk, isTopResume } = useBrand()
  const { cx, classes } = useCritiqueStyles()

  const mappedSections = useMemo(
    () =>
      sections.map((section, i) => {
        return {
          name: section.sections,
          title: isPerfect
            ? mapTitles
              ? t(`${CritiqueTRef.sectionHeaders}.${TITLES_MAP[i]}`) ||
                section.titles
              : section.titles || ''
            : section.titles,
          html: section?.Bodies?.map((body) => body.voice_body).join(' '),
        }
      }),
    [sections, t, mapTitles, isPerfect]
  )

  if (mappedSections.length === 0)
    return (
      <Box display="flex" flexDirection="column" gap="40px">
        <Box display="flex" flexDirection="column" gap="24px">
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <CritiqueTextColumn
                content={{
                  body: ' ',
                  html: ' ',
                }}
                key={i}
                index={i + 1}
                variant={variant}
              />
            ))}
        </Box>
      </Box>
    )

  const gradientScaleSection = isV2
    ? 'design-structure-formatting'
    : 'digital-readiness'

  const showAICallout = isPerfect && (isTopCV || isTopCVuk || isTopResume)

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={isV2 ? (isPerfect ? '56px' : '40px') : '40px'}
    >
      {mappedSections.map((section, i) => {
        return (
          <Box display="flex" flexDirection="column" gap="24px" key={i}>
            <CritiqueTextColumn
              content={section}
              index={i + 1}
              variant={variant}
            />
            {section.name === gradientScaleSection &&
            data?.resume_file_size &&
            data?.resume_file_percentile ? (
              <GradientScale
                header={t(`${CritiqueTRef.widgets}.filesize.title`, {
                  flagshipProduct: capitalize(flagshipProduct),
                })}
                description={`
                  ${t(`${CritiqueTRef.widgets}.filesize.resumeIs`, {
                    flagshipProduct,
                  })}
                  ${t(
                    `${CritiqueTRef.widgets}.filesize.${percentileToText(
                      data.resume_file_percentile
                    )}`
                  )}`}
                size={data.resume_file_size}
                percentile={data.resume_file_percentile}
                variant={variant}
              />
            ) : null}
            {section.name === 'design-structure-formatting' && isV2 ? (
              <BrowsePackagesSection />
            ) : null}
            {section.name === 'design-structure-formatting' && showAICallout ? (
              <InfoBox
                title={ContentString.perfectAIInfo.header}
                node={
                  <List
                    style={{
                      margin: 0,
                      padding: '0 0 0 25px',
                      listStyleType: 'disc',
                    }}
                  >
                    {ContentString.perfectAIInfo.items.map((bullet, i) => (
                      <li key={i}>
                        <Typography
                          variant="inherit"
                          className={cx(classes.typographyBody)}
                        >
                          {bullet}
                        </Typography>
                      </li>
                    ))}
                  </List>
                }
              />
            ) : null}
          </Box>
        )
      })}
    </Box>
  )
}

export const CritiqueTextColumn: React.FC<
  {
    content: TextColumnSimpleProps
    variant?: string
  } & { index?: number }
> = ({ content: { name, title, body, html }, index, variant }) => {
  return (
    <TextColumn
      name={name}
      title={title}
      body={body}
      html={html}
      index={index ?? 0}
      variant={variant}
    />
  )
}

export const TextColumn: React.FC<
  (TextColumnProps | TextColumnSimpleProps) & { index?: number }
> = ({ name, title, text, body, html, index, variant }) => {
  const {
    active: [, , { isPerfect }],
    request: { isLoading },
  } = useContext(CritiqueDataContext) as {
    active: ICritiqueProfile
    request: { isLoading: boolean }
  }
  const { classes, cx } = useCritiqueStyles()

  const showTitle = title || title === ''

  const isV2 = variant === 'v2'

  return (
    <Box
      id={index ? `critique-section-${index}` : ''}
      className={cx(classes.sectionScrollMargin)}
      display="flex"
      flexDirection="column"
      gap={isPerfect ? '24px' : '16px'}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={cx(classes.critiqueSection)}
      >
        <SkeletonLoader
          skeleton={{
            variant: 'text',
            width: 150,
            height: 40,
          }}
          condition={!!showTitle}
          abort={!showTitle && !isLoading}
        >
          {title && title !== '' ? (
            <Typography
              variant="inherit"
              className={cx(classes.typographySubhead, classes.bold)}
            >
              {title}
            </Typography>
          ) : null}
        </SkeletonLoader>
      </Box>
      <SkeletonLoader
        skeleton={{
          variant: 'rectangular',
          width: '100%',
          height: 150,
        }}
        condition={!!(showTitle || text)}
        abort={!(showTitle || text) && !isLoading}
        className={[classes.skeletonMarginTop]}
      >
        {text && text.raw ? (
          <RichText
            rawBody={text.raw}
            references={text.references}
            options={{
              props: {
                [BLOCKS.PARAGRAPH]: {
                  className: classes.typographyCaption,
                },
              },
            }}
          />
        ) : null}
      </SkeletonLoader>
      <SkeletonLoader
        condition={!!(showTitle || body)}
        abort={!(showTitle || body) && !isLoading}
      >
        {body ? (
          <Typography variant="inherit" className={cx(classes.typographyBody)}>
            {body}
          </Typography>
        ) : null}
      </SkeletonLoader>
      <SkeletonLoader condition={!!html} abort={!html && !isLoading}>
        {html && html !== '' ? (
          <Typography
            variant="inherit"
            className={classes.typographyBody}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        ) : null}
      </SkeletonLoader>
      {name === 'intro' && !isV2 && <ImperfectCritiqueIntro />}
    </Box>
  )
}
