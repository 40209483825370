import React, { useContext, useMemo } from 'react'
import {
  Box,
  Typography,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { navigate } from 'gatsby'
import { useCritiqueStyles } from './styles'
import { CritiqueTextLines, ICritiqueInfoItems } from './TextLines'
import { ContentString } from './content'
import InfoHand from './../../images/info-hand.png'
import InfoWarn from './../../images/info-warn.png'
import InfoFace from './../../images/info-face.png'
import InfoScroll from './../../images/info-scroll.png'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import { interpolateString } from '../../utils/interpolateString'
import useBrand from '../../hooks/useBrand'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'
import { capitalize } from '../../utils/string'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import useTidyLink from '../../hooks/useTidyLink'
import { useCritiquePage } from './CritiqueProvider'
import { useBETelemetry } from '../../hooks/useTelemetry'
import { useSessionQuery } from '../../components/Providers/UserSession'

const ICON_MAP = {
  hand: InfoHand,
  warn: InfoWarn,
  face: InfoFace,
  scroll: InfoScroll,
}

const V3_NEXT_STEPS_DATA = [
  {
    subheader: 'Let our writers craft<br/> your perfect resume',
    header: 'Resume Writing Services',
    body: 'Our resume packages include a professionally designed, ATS-friendly resume that highlights your achievements and impresses hiring managers. Our expert writers ensure it captures your unique story authentically.',
    button: 'Enhance My Resume',
    icon: 'star',
    telemetryEventName: 'enhance_resume_next_steps_click',
  },
  {
    subheader: 'Leave your job<br/> search to us!',
    header: 'Job Search Services',
    body: 'Our team handles everything. Get a perfect resume from our experts or use our builder. We find jobs, complete applications, prep you for interviews, and connect you with recruiters. Just show up for the interview.',
    button: 'Get Expert Help',
    icon: 'search',
    tab: 'job_search',
    telemetryEventName: 'get_expert_help_next_steps_click',
  },
]

const V3_NEXT_STEPS_DATA_MOBILE = [
  {
    subheader: 'Professional',
    header: 'Resume Writing Services',
    body: 'Get noticed with a handcrafted, expertly written professional resume.',
    button: 'Get Expert Help',
    icon: 'star',
    telemetryEventName: '',
  },
  {
    subheader: 'Comprehensive',
    header: 'Job Search Services',
    body: 'Our experts will source jobs for you, apply for you, and prepare you to get the job you want!',
    button: 'Learn How',
    icon: 'search',
    tab: 'job_search',
    telemetryEventName: '',
  },
]

const TEMP_RULE_IGNORE_FRENCH_INTERVIEWCOACHING = true

const majorExpertFixList = ContentString.majorIssueExpert

const ENABLE_V3_NEXT_STEPS = false

interface CritiqueNextStepsProps {
  heading: string
  items: {
    html: string
  }[]
  variant?: string
}

export const mapBulletPoints = (
  points: { voice_body: string }[]
): { html: string }[] => {
  return (points || []).map((point) => {
    return {
      html: point.voice_body,
    }
  })
}

export const CritiqueRecsNextSteps: React.FC<CritiqueNextStepsProps> = ({
  heading,
  items,
  variant = 'v1',
}) => {
  const { classes, cx } = useCritiqueStyles()
  const { t } = useTranslation()

  const isV2 = variant === 'v2'

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        id="critique-section-nextsteps"
        className={cx(classes.critiqueSection, classes.sectionScrollMargin)}
      >
        <Typography
          variant="inherit"
          className={cx(
            classes[isV2 ? 'typographyHeader3Desktop' : 'typographyHeader2'],
            classes.bold
          )}
        >
          {t(`${CritiqueTRef.sectionHeaders}.recsSteps`)}
        </Typography>
        <Typography
          variant="inherit"
          className={cx('critique-text-line', classes.typographyBody)}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </Box>
      <CritiqueMajorIssues items={items} variant={variant} />
    </Box>
  )
}

const NextStepSingleV3: React.FC<(typeof V3_NEXT_STEPS_DATA)[0]> = ({
  subheader,
  header,
  body,
  button,
  icon,
  tab,
  telemetryEventName,
}) => {
  const { classes, cx } = useCritiqueStyles()
  const telemetry = useBETelemetry()

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={cx('next-step-item', `bg-icon-${icon}`)}
      gap="16px"
      minWidth="250px"
      flex={1}
    >
      <span
        className={classes.typographyCaption}
        dangerouslySetInnerHTML={{ __html: subheader }}
      />
      <span className={classes.typographyHeader3Desktop}>{header}</span>
      <span className={classes.typographyBody2}>{body}</span>
      <Button
        className="linkSmall"
        onClick={async () => {
          if (telemetryEventName) {
            telemetry.track({
              event: telemetryEventName,
              properties: { event_type: 'critique_event' },
            })
          }

          const url = '/new/job-search'

          await navigate(url)
        }}
      >
        {button}
      </Button>
    </Box>
  )
}

const NextStepsV3 = () => {
  const { classes } = useCritiqueStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { display_name } = useBrand()

  const nextStepsData = isMobile ? V3_NEXT_STEPS_DATA_MOBILE : V3_NEXT_STEPS_DATA

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="36px"
      mt={isMobile ? '30px' : '80px'}
    >
      {!isMobile && (
        <Box display="flex" flexDirection="column" gap="16px">
          <span className={classes.typographyHeader2Desktop}>Your Next Steps</span>
          <span className={classes.typographyBody}>
            Team up with {display_name} and maximize your Job opportunities.
          </span>
        </Box>
      )}
      <Box
        display="flex"
        gap="28px"
        flexWrap="wrap"
        className="next-steps-container menu"
        style={{ borderRadius: '16px' }}
        width="fit-content"
      >
        {nextStepsData.map((item, index) => (
          <NextStepSingleV3 key={index} {...item} />
        ))}
      </Box>
    </Box>
  )
}

const CritiqueMajorIssues: React.FC<{
  items: { html: string }[]
  variant?: string
}> = ({ items }) => {
  const { t } = useTranslation()
  const brand = useBrand()
  const {
    active: [, , { isPerfect, isTroubled, isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { classes, cx } = useCritiqueStyles()
  const { locale } = useSiteMetadata()
  const { state } = useCritiquePage()
  const { data } = useSessionQuery()

  const isV2 = state?.variant === 'v2'
  const isV3 = state?.variant === 'v3'

  const isPartnerMonster = useMemo(
    () => data?.partnerName.includes('Monster'),
    [data]
  )

  const contentKey = useMemo(() => {
    if (isTroubled) return 'troubled'
    if (isNearlyPerfect) return 'nearlyperfect'
    return 'normal'
  }, [isTroubled, isNearlyPerfect])

  const contentListData = majorExpertFixList[contentKey] as {
    items: ICritiqueInfoItems[]
    brand?: string
    button?: {
      text: string
      url?: string
      tUrl?: string
    }
    icon?: string
    version?: string | string[]
  }[]

  const contentList = contentListData.filter((content) => {
    if (content?.version && content?.version !== state?.variant) return false

    if (content?.brand && content?.brand !== brand?.name) return false

    if (content?.button?.url?.includes('job-search') && isPartnerMonster)
      return false

    if (TEMP_RULE_IGNORE_FRENCH_INTERVIEWCOACHING || brand.isZipJob) {
      if (content?.icon === 'face' && (locale === 'fr' || brand.isZipJob)) {
        return false
      }
    }
    return true
  })

  return (
    <Box display="flex" flexDirection="column" gap={0}>
      {isV2 && isPerfect && (
        <Box marginBottom="32px">
          <Typography variant="inherit" className={cx(classes.typographyBody)}>
            {t(`${CritiqueTRef.widgets}.majorIssues.title`, brand)}
          </Typography>
        </Box>
      )}
      {items?.length > 0 && (
        <Box
          display="flex"
          flexDirection="column"
          className={cx(classes.majorIssuesContainer, {
            [classes.majorIssuesV2]: isV2 || isV3,
          })}
        >
          {isPerfect && !isV2 && (
            <Box
              className={cx(
                classes.majorIssuesItemContainerX,
                classes.majorIssuesItemContainerY
              )}
            >
              <Typography
                variant="inherit"
                className={cx(classes.typographySubhead)}
              >
                {t(`${CritiqueTRef.widgets}.majorIssues.title`, brand)}
              </Typography>
            </Box>
          )}
          {items.map(({ html }, i) => (
            <CritiqueMajorIssueItem
              icon={
                <img src={InfoWarn} alt="Icon Warning" width="48px" height="48px" />
              }
              html={html}
              key={i}
              showDivider={!(isV2 && i === 0)}
            />
          ))}
        </Box>
      )}
      {contentList &&
        !!contentList?.length &&
        contentList.map(({ items, button, icon = 'hand' }: any, i) => (
          <CritiqueMajorIssueExpertFix
            items={items}
            button={button}
            key={i}
            isFirst={!isPerfect && i === 0}
            isLast={i === contentList.length - 1}
            icon={icon}
          />
        ))}
      {isV2 && !isTroubled && (
        <Box marginTop="32px">
          <Typography variant="inherit" className={cx(classes.typographyBody)}>
            Our team of resume writers are experts at fixing these types of problems
            to help articulate your career path progression over the years.
          </Typography>
        </Box>
      )}
      {isV3 && ENABLE_V3_NEXT_STEPS && <NextStepsV3 />}
    </Box>
  )
}

const CritiqueMajorIssueItem: React.FC<{
  item?: ICritiqueInfoItems[]
  html?: string
  icon: React.ReactNode
  button?: {
    text: string
    url?: string
    tUrl?: string
  }
  showDivider?: boolean
}> = ({ item, html, icon = '', button, showDivider = true }) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()
  const { t } = useTranslation()
  const tidyTheLink = useTidyLink()
  const telemetry = useBETelemetry() // Initialize backend telemetry

  const btnUrl = button
    ? button?.tUrl
      ? `/${t(`${CritiqueTRef.links}.${button?.tUrl}`, {
          brandProduct: brand.flagshipProduct?.toLowerCase(),
        })}`.toLowerCase()
      : button?.url
      ? interpolateString(button.url, {
          ...brand,
        })?.toLowerCase()
      : ''
    : ''

  const btnText = button
    ? t(`${CritiqueTRef.widgets}.majorIssues.${button?.text}`, {
        ...brand,
        flagshipProductCapitalized: capitalize(brand.flagshipProduct),
      })
    : ''

  const itemsTranslated = item?.map(({ text, variant, url, color, tooltip }) => {
    return {
      text: t(`${CritiqueTRef.widgets}.majorIssues.${text}`, brand),
      variant,
      url,
      color,
      tooltip,
    }
  })

  return (
    <Box className={cx(classes.majorIssuesItemContainerY)}>
      {showDivider && <Divider />}
      <Box className={cx(classes.majorIssuesItemContainerX)}>
        <Box display="flex" flexDirection="row" alignItems="flex-start" gap="16px">
          {icon}
          <Box display="flex" flexDirection="column" gap="16px">
            <CritiqueTextLines html={html} items={itemsTranslated} />
            {button && (
              <Button
                data-testid="cta-get-an-expert-to-rewrite-your-resume"
                {...(button.url && { href: btnUrl, target: '_blank' })}
                className={cx(classes.mainButton, classes.hideMobileSm)}
                onAuxClick={(e) => {
                  telemetry.track({
                    event: 'click_cta_button',
                    properties: {
                      label: e.currentTarget.textContent,
                    },
                  })
                }}
                onClick={(e) => {
                  telemetry.track({
                    event: 'click_cta_button',
                    properties: {
                      label: e.currentTarget.textContent,
                    },
                  })
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: btnText }} />
              </Button>
            )}
          </Box>
        </Box>
        {button && (
          <Button
            {...(button.url && { href: btnUrl, target: '_blank' })}
            className={cx(
              classes.mainButton,
              classes.fullWidthButton,
              classes.showMobileSm,
              classes.wrapTextSmall
            )}
            onAuxClick={(e) => {
              telemetry.track({
                event: 'click_cta_button',
                properties: {
                  label: e.currentTarget.textContent,
                  url: tidyTheLink(btnUrl), // Include tidied link in telemetry
                },
              })
            }}
            onClick={(e) => {
              telemetry.track({
                event: 'click_cta_button',
                properties: {
                  label: e.currentTarget.textContent,
                  url: tidyTheLink(btnUrl), // Include tidied link in telemetry
                },
              })
            }}
          >
            {btnText}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const CritiqueMajorIssueExpertFix: React.FC<{
  items: ICritiqueInfoItems[]
  button?: {
    text: string
    url?: string
  }
  isFirst: boolean
  isLast: boolean
  icon?: string
}> = ({ items, button, isFirst, isLast, icon = 'hand' }) => {
  const {
    active: [, , { isPerfect, isTroubled, isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box
        display="flex"
        flexDirection="column"
        className={cx(classes.majorIssuesExpertContainer, {
          [classes.majorIssuesItemFirstItem]: isFirst,
          [classes.majorIssuesItemLastItem]: isLast,
          [classes.majorIssuesPerfect]: isPerfect,
          [classes.majorIssuesNotPerfect]: isTroubled || isNearlyPerfect,
        })}
      >
        <CritiqueMajorIssueItem
          icon={
            <img
              src={ICON_MAP[icon as keyof typeof ICON_MAP]}
              alt="Icon Hand Writing"
              width="48px"
              height="48px"
            />
          }
          item={items}
          button={button}
          showDivider={false}
        />
      </Box>
    </Box>
  )
}
