import React from 'react'
import { Skeleton, SkeletonProps } from '@mui/material'
import { useCritiqueStyles } from './styles'

export const SkeletonLoader: React.FC<{
  condition?: boolean
  abort?: boolean
  skeleton?: SkeletonProps
  children: React.ReactNode
  className?: string[]
  style?: React.CSSProperties
}> = ({
  condition = true,
  abort = false,
  skeleton = {},
  children,
  className = [],
  style,
}) => {
  const { classes, cx } = useCritiqueStyles()

  if (abort) return null

  return condition ? (
    children
  ) : (
    <Skeleton
      width={100}
      animation="pulse"
      {...skeleton}
      className={cx(className, classes.skeletonLoader)}
      classes={{
        wave: classes.skeletonLoaderWave,
      }}
      style={style}
    />
  )
}
