import { useEffect, useRef } from 'react'

const toggleElementParent = (element: HTMLElement, display: boolean) => {
  element &&
    element?.parentElement &&
    (element.parentElement.style.display = display ? 'flex' : 'none')
}

export const useToggleParent = (
  condition: boolean,
  disabled: boolean
): React.RefObject<HTMLDivElement> => {
  const targetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    !disabled &&
      targetRef.current &&
      toggleElementParent(targetRef.current, condition)
  }, [disabled, condition, targetRef])

  return targetRef
}
