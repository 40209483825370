import React from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { CritiqueChipList, CritiqueChipProps } from './ChipList'
import { CritiqueTRef } from './Translation'
import { useTranslation } from 'react-i18next'

interface CritiqueSkillSectionProps {
  header?: string
  items: CritiqueSkillList[]
}

interface CritiqueSkillList {
  title: string
  items: CritiqueChipProps[]
}

export const mapSkillsList = (
  skills: Record<
    string,
    {
      name: string
      months: number
    }[]
  >
): CritiqueSkillList[] => {
  return Object.values(skills).map((items, i) => {
    return {
      title: ['topKeywords', 'otherCoreCompetencies', 'lessWeightedSkills'][i],
      items: items.map(({ name }) => ({
        text: name,
        variant: i === 0 ? 'active' : 'neutral',
      })),
    }
  })
}

export const CritiqueSkillSection: React.FC<CritiqueSkillSectionProps> = ({
  header,
  items,
}) => {
  const { t } = useTranslation()
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {header && (
        <Typography
          variant="inherit"
          className={cx(classes.typographySubhead, classes.bold)}
        >
          {header}
        </Typography>
      )}
      {items.map(({ items, title }, i) =>
        title ? (
          <CritiqueChipList
            key={i}
            header={t(`${CritiqueTRef.widgets}.applicantATS.${title}`)}
            items={items}
          />
        ) : null
      )}
    </Box>
  )
}
