import React, { FC } from 'react'

import { Box } from '../Box'
import { Text } from '../Text'
import { CritiqueButton } from '../Button'
import { ActionLinkPost, Button as ButtonType } from '../../../types/page'
import { Debug } from '../../Debug'

import {
  CritiqueAtsSection,
  CritiqueFullSections,
  CritiqueNextStepsList,
  CritiqueNextStepsSection,
  CritiqueRawDataSection,
  CritiqueSidebarLinks,
  CritiqueWriterSignatureSection,
} from '../Sections'
import {
  CritiqueSideTestimonials,
  CritiqueSideTestimonialsProps,
} from '../SideTestimonials'
import { CritiqueSidePrices, SidePricesProps } from '../SidePrices'
import { CritiqueHeaderV2 } from '../CritiqueHeaderV2'
import { CritiqueBannerLogo } from '../BannerLogo'
import { CritiquePriceColumns, PriceColumnsProps } from '../PriceColumns'
import { CTAFooter } from '../CTAFooter'
import { CritiqueFAQGroup } from '../CritiqueFAQGroup'
import { FAQGroupProps } from 'components/FAQ/FAQGroup'

export const enum Entry {
  Button = 'ContentfulButton',
  CritiqueBox = 'ContentfulCritiqueBox',
  CritiqueText = 'ContentfulCritiqueText',
  CritiqueHeaderV2 = 'ContentfulCritiqueHeaderV2',
  CritiqueNextStepsList = 'ContentfulCritiqueNextStepsList',
  CritiqueSidebarLinks = 'ContentfulCritiqueSidebarLinks',
  CritiqueInfoBox = 'ContentfulCritiqueInfoBox',
  CritiqueRawDataList = 'ContentfulCritiqueRawDataList',
  CritiqueRawDataListItem = 'ContentfulCritiqueRawDataListItem',
  CritiqueChipList = 'ContentfulCritiqueChipList',
  CritiqueTextColumn = 'ContentfulCritiqueTextColumn',
  CritiqueFullSections = 'ContentfulCritiqueFullSections',
  CritiqueRawDataSection = 'ContentfulCritiqueRawDataSection',
  CritiqueAtsSection = 'ContentfulCritiqueAtsSection',
  CritiqueNextStepsSection = 'ContentfulCritiqueNextStepsSection',
  CritiqueSideTestimonials = 'ContentfulCritiqueSideTestimonials',
  CritiqueSidePrices = 'ContentfulCritiqueSidePrices',
  CritiqueWriterSignature = 'ContentfulCritiqueWriterSignature',
  CritiqueBannerLogo = 'ContentfulCritiqueBannerLogo',
  CritiquePriceColumns = 'ContentfulCritiquePriceColumns',
  CritiqueCTABanner = 'ContentfulCritiqueCtaBanner',
  FAQGroup = 'ContentfulFrequentlyAskedQuestionGroup',
}

export type ContentfulEntryProps = {
  __typename: Entry | string
  contentful_id: string

  [key: string]: any
}

export const ContentfulEntry: FC<ContentfulEntryProps> = ({
  __typename,
  contentful_id,
  ...props
}) => {
  switch (__typename) {
    case Entry.CritiqueBox:
      return (
        <Box
          justifyContent={props.justifyContent}
          alignItems={props.alignItems}
          direction={props.direction}
          wrap={props.wrap}
          alignContent={props.alignContent}
          grow={props.grow}
          shrink={props.shrink}
          basis={props.basis}
          alignSelf={props.alignSelf}
          gap={props.gap}
          items={props.items}
          classes={props.classes}
        />
      )

    case Entry.CritiqueText:
      return (
        <Text
          children={props.text.text}
          variant={props.variant}
          fontWeight={props.fontWeight}
        />
      )

    case Entry.CritiqueHeaderV2:
      return <CritiqueHeaderV2 />

    case Entry.CritiqueBannerLogo:
      return <CritiqueBannerLogo />

    case Entry.Button:
      return (
        <CritiqueButton
          {...(props as ContentfulEntryProps & ButtonType<ActionLinkPost>)}
        />
      )

    case Entry.CritiqueNextStepsList:
      return (
        <CritiqueNextStepsList
          {...(props as ContentfulEntryProps & { items: any[] })}
        />
      )

    case Entry.CritiqueSidebarLinks:
      return (
        <CritiqueSidebarLinks
          {...(props as ContentfulEntryProps & { title: string; text: any })}
        />
      )

    case Entry.CritiqueAtsSection:
      return (
        <CritiqueAtsSection
          {...(props as ContentfulEntryProps & { variant?: string })}
        />
      )

    case Entry.CritiqueFullSections:
      return (
        <CritiqueFullSections
          {...(props as ContentfulEntryProps & {
            hideIntro: boolean
            mapTitles: boolean
            variant?: string
          })}
        />
      )

    case Entry.CritiqueRawDataSection:
      return (
        <CritiqueRawDataSection
          {...(props as ContentfulEntryProps & { variant?: string })}
        />
      )

    case Entry.CritiqueWriterSignature:
      return (
        <CritiqueWriterSignatureSection
          {...(props as ContentfulEntryProps & {
            name: string
            variant?: string
          })}
        />
      )

    case Entry.CritiqueNextStepsSection:
      return (
        <CritiqueNextStepsSection
          {...(props as ContentfulEntryProps & { variant?: string })}
        />
      )

    case Entry.CritiqueSideTestimonials:
      return (
        <CritiqueSideTestimonials
          {...(props as ContentfulEntryProps & CritiqueSideTestimonialsProps)}
        />
      )

    case Entry.CritiqueSidePrices:
      return (
        <CritiqueSidePrices
          {...(props as ContentfulEntryProps & SidePricesProps)}
        />
      )

    case Entry.CritiquePriceColumns:
      return (
        <CritiquePriceColumns
          {...(props as ContentfulEntryProps & PriceColumnsProps)}
        />
      )

    case Entry.FAQGroup:
      return <CritiqueFAQGroup faq={props as FAQGroupProps['group']} />

    case Entry.CritiqueCTABanner:
      return <CTAFooter />

    default:
      return <Debug {...{ ...props, __typename, contentful_id }} />
  }
}
