import React, { useContext } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import Star from './../../images/star.png'
import CategoriesIcon from './../../images/blog-categ.png'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import { useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'

const StarIcon: React.FC<{
  active: boolean
  onHover: () => void
  onClick: () => void
}> = ({ active, onHover, onClick }) => (
  <img
    className="star"
    src={Star}
    onClick={onClick}
    onMouseOver={() => onHover()}
    alt="Star Icon"
    width="48px"
    height="48px"
    style={{
      filter: `saturate(${active ? 1 : 0})`,
      transition: 'filter 0.3s, transform 0.2s ease-in-out',
    }}
  />
)

export const CritiqueResultsBanner = () => {
  const [step, setStep] = React.useState(0)
  const { classes } = useCritiqueStyles()
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const moveStep = () => {
    setStep((step) => step + 1)
  }

  return (
    <Box className={classes.resultsBanner} padding="64px 0px">
      <Container className={classes.critiqueResultsContainer} disableGutters>
        {step === 0 && (
          <CritiqueResultsStepRate
            name={activeCritique?.critiquer?.critiquer_first_name || 'our'}
            moveStep={moveStep}
          />
        )}
        {step === 1 && <CritiqueResultsStepTwo moveStep={moveStep} />}
        {step === 2 && <CritiqueResultsStepThree />}
      </Container>
    </Box>
  )
}

const CritiqueResultsStepRate: React.FC<{
  name: string
  moveStep: () => void
}> = ({ name, moveStep }) => {
  const { t } = useTranslation()
  const [active, setActive] = React.useState(0)
  const { classes, cx } = useCritiqueStyles()

  const handleStarClick = (i: number) => {
    moveStep()
  }

  return (
    <Box display="flex" flexDirection="column" gap="32px" alignItems="center">
      <Typography
        variant="inherit"
        className={cx(classes.typographySubhead, classes.white)}
      >
        {t(`${CritiqueTRef.widgets}.resultsBanner.line1`)}
      </Typography>
      <Typography
        variant="inherit"
        className={cx(classes.typographySubhead, classes.white)}
      >
        {t(`${CritiqueTRef.widgets}.resultsBanner.line2`, { name })}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        gap="8px"
        onMouseLeave={() => setActive(0)}
        style={{ cursor: 'pointer' }}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <StarIcon
            active={active >= i}
            onHover={() => setActive(i)}
            key={i}
            onClick={() => handleStarClick(i)}
          />
        ))}
      </Box>
    </Box>
  )
}

const CritiqueResultsStepTwo: React.FC<{
  moveStep: () => void
}> = ({ moveStep }) => {
  const { t } = useTranslation()
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="32px" alignItems="center">
      <Typography
        variant="inherit"
        className={cx(classes.typographySubhead, classes.white)}
      >
        {t(`${CritiqueTRef.widgets}.resultsBanner.line3`)}
      </Typography>
      <Typography
        variant="inherit"
        className={cx(classes.typographySubhead, classes.white)}
      >
        {t(`${CritiqueTRef.widgets}.resultsBanner.line4`)}
      </Typography>
      <textarea
        style={{
          width: '420px',
          height: '100px',
          padding: '16px',
          fontSize: '16px',
          borderRadius: '8px',
          border: 'none',
          resize: 'none',
          backgroundColor: 'white',
        }}
      />
      <Button onClick={moveStep} className={classes.mainButton}>
        {t(`${CritiqueTRef.text}.submitFeedback`)}
      </Button>
    </Box>
  )
}

const CritiqueResultsStepThree: React.FC = () => {
  const { t } = useTranslation()
  const { classes, cx } = useCritiqueStyles()
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  return (
    <Box display="flex" flexDirection="column" gap="32px" alignItems="center">
      <img
        src={CategoriesIcon}
        alt="Categories Icon"
        width="224px"
        height="106px"
      />
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        maxWidth={345}
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant="inherit"
          className={cx(classes.typographySubhead, classes.white)}
        >
          {t(`${CritiqueTRef.widgets}.resultsBanner.line5`)}
        </Typography>
        <Typography
          variant="inherit"
          className={cx(classes.typographySubhead, classes.white)}
        >
          {t(`${CritiqueTRef.widgets}.resultsBanner.line6`, {
            critiquer_name:
              activeCritique?.critiquer?.critiquer_first_name || 'our',
            critiquer_email:
              activeCritique?.critiquer?.critiquer_email || 'our',
          })}
        </Typography>
      </Box>
    </Box>
  )
}
