import React, { useContext } from 'react'
import { Box, Divider } from '@mui/material'
import FAQGroup, { FAQGroupProps } from '../../components/FAQ/FAQGroup'
import useBrand from '../../hooks/useBrand'
import { useCritiqueStyles } from './styles'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import { capitalize } from '../../utils/string'

export const CritiqueFAQGroup: React.FC<{ faq: FAQGroupProps['group'] }> = ({
  faq,
}) => {
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { classes } = useCritiqueStyles()
  const brand = useBrand()

  const upperCasedBrand = capitalize(brand?.flagshipProduct || '')

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginBottom="48px"
      className={classes.critiqueFaqSection}
      id="critique-faq-section"
    >
      <Box marginTop={3}>
        <Divider />
      </Box>
      <FAQGroup faqSection group={faq} />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        className={classes.faqBottomText}
      >
        <Box width={250}>
          Have questions about our {brand.flagshipProduct} services? —{' '}
          <a
            href={`mailto:${activeCritique?.critiquer?.critiquer_email}?subject=${upperCasedBrand} Questions`}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            Send us an email
          </a>
        </Box>
      </Box>
    </Box>
  )
}
