import React, { useContext } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import { ProductPackageDetail } from 'types/productPageV2'
import { useSKU } from '../../hooks/useSKUs'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '../../utils/mixpanel'
import useTidyLink from '../../hooks/useTidyLink'
import { MixpanelEventTypes, MixpanelEvents } from '../../types/mixpanel'

const PRICE_AVATAR_IMAGE_CDN =
  'https://images.ctfassets.net/7thvzrs93dvf/7uDKbZvqV58PL489I7phZ3/d84cc42da7872368016bffbdf34d03dc/price-avatars.png'

const PRICE_AVATAR_IMAGE_IMPERFECT_CDN =
  'https://images.ctfassets.net/7thvzrs93dvf/37cs4ODx5hhpvQlRJ6MOmh/f295b90f3fbd2856f0297891ba664641/Group_337198.png'

export interface SidePricesProps {
  productPackageRows: ProductPackageDetail[]
}

const ENABLE_PRICES_DISPLAY = false

const Price: React.FC<ProductPackageDetail> = ({
  title,
  description,
  productPackage,
}) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()

  const handlebarsContext = useHandlebarsContext()

  const sku = useSKU(
    productPackage.items,
    productPackage.planCode,
    null,
    productPackage.sort
  )

  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <a
          href={`../${brand.flagshipProduct}-writing`}
          className={cx(classes.priceText, classes.link)}
          target="_blank"
          rel="noreferrer"
        >
          <Typography
            variant="inherit"
            className={cx(classes.typographyMobileBody, classes.bold)}
          >
            {title}
          </Typography>
        </a>
        {ENABLE_PRICES_DISPLAY && sku && (
          <a
            href={`../${brand.flagshipProduct}-writing`}
            className={cx(classes.priceText, classes.link)}
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              variant="inherit"
              className={classes.typographyMobileBody}
            >
              {sku.formattedAmount}
            </Typography>
          </a>
        )}
      </Box>
      <Typography
        variant="inherit"
        className={cx(classes.typographyCaption, classes.neutral)}
      >
        {insertHandlebarsTemplate(description, handlebarsContext)}
      </Typography>
    </Box>
  )
}

const PricingHeader = () => {
  const {
    active: [, , { isPerfect, isNearlyPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const tidyTheLink = useTidyLink()
  const critiqueEventTrackOn = useMpEventTrackOn(
    MixpanelEventTypes.CritiqueEvent
  )

  const headerText = isNearlyPerfect
    ? 'Struggling with your job search? Talk to an interview coach!'
    : `Let our writers craft your perfect ${brand.flagshipProduct}`

  return (
    <Box display="flex" flexDirection="column" gap="16px" alignItems="center">
      <img
        alt="Writers"
        src={
          isPerfect ? PRICE_AVATAR_IMAGE_CDN : PRICE_AVATAR_IMAGE_IMPERFECT_CDN
        }
        width={isPerfect ? 134 : 119}
        height={isPerfect ? 72 : 62}
      />
      <Typography
        variant="inherit"
        align="center"
        className={cx(
          classes.typographyHeader3Desktop,
          classes.typographySmallMd
        )}
      >
        {headerText}
      </Typography>
      <Button
        data-testid="cta-browse-our-packages"
        variant="contained"
        color="primary"
        href={`../${brand.flagshipProduct}-writing`}
        target="_blank"
        className={cx(classes.mainButton, classes.mainButtonSmallMd)}
        onAuxClick={(e) => {
          handleMpCTAClickTracking(
            e.currentTarget.textContent,
            tidyTheLink(`/${brand.flagshipProduct?.toLowerCase()}-writing`),
            {
              eventName: MixpanelEvents.BrowseOurPackagesClicks,
              trackingData: {
                event_type: MixpanelEventTypes.CritiqueEvent,
              },
              trackOn: critiqueEventTrackOn,
            }
          )
        }}
        onClick={(e) => {
          handleMpCTAClickTracking(
            e.currentTarget.textContent,
            tidyTheLink(`/${brand.flagshipProduct?.toLowerCase()}-writing`),
            {
              eventName: MixpanelEvents.BrowseOurPackagesClicks,
              trackingData: {
                event_type: MixpanelEventTypes.CritiqueEvent,
              },
              trackOn: critiqueEventTrackOn,
            }
          )
        }}
      >
        Browse Our Packages
      </Button>
    </Box>
  )
}

export const CritiqueSidePrices: React.FC<SidePricesProps> = ({
  productPackageRows,
}) => {
  const { classes } = useCritiqueStyles()

  return (
    <Box className={classes.pricingMainContainer} gap="24px">
      <PricingHeader />
      <Box display="flex" flexDirection="column" gap="22px" mt={'24px'}>
        {productPackageRows.map((productPackageDetail, index) => (
          <Box key={index} display="flex" flexDirection="column" gap="16px">
            <Divider />
            <Price {...productPackageDetail} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
