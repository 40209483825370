import React, { CSSProperties, FC } from 'react'
import { BaseReference } from '../../../types/references'
import { ContentfulEntry } from '../ContentfulEntry'
import { useCritiqueStyles } from '../styles'

export type BoxProps = {
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']
  direction?: CSSProperties['flexDirection']
  wrap?: CSSProperties['flexWrap']
  alignContent?: CSSProperties['alignContent']
  grow?: CSSProperties['flexGrow']
  shrink?: CSSProperties['flexShrink']
  basis?: CSSProperties['flexBasis']
  alignSelf?: CSSProperties['alignSelf']
  gap?: CSSProperties['gap']
  items?: BaseReference<string>[]
  classes?: string[]
}

export const Box: FC<BoxProps> = (props) => {
  const { classes: critiqueClasses, cx } = useCritiqueStyles()

  const {
    justifyContent,
    alignItems,
    direction,
    wrap,
    alignContent,
    grow,
    shrink,
    basis,
    alignSelf,
    gap,
    items = [],
    classes = [],
  } = props

  return (
    <div
      style={{
        display: 'flex',
        justifyContent,
        alignItems,
        flexDirection: direction,
        flexWrap: wrap,
        alignContent,
        flexGrow: grow,
        flexShrink: shrink,
        flexBasis: basis,
        alignSelf,
        gap,
      }}
      className={cx(critiqueClasses.contentfulBox, classes, {
        [critiqueClasses.contentContainer]: classes?.includes(
          'critique-content-container'
        ),
        [critiqueClasses.critiqueMainContent]: classes?.includes(
          'critique-main-content'
        ),
        [critiqueClasses.critiqueSidebar]:
          classes?.includes('critique-sidebar'),
        [critiqueClasses.critiqueV1]: classes?.includes('critique-v1'),
        [critiqueClasses.critiqueV2]: classes?.includes('critique-v2'),
        [critiqueClasses.mainContent]: classes?.includes('main-content'),
        [critiqueClasses.sidebarV2]: classes?.includes('v2-sidebar'),
        [critiqueClasses.fixedSidebar]: classes?.includes('fixedSidebar'),
        [critiqueClasses.critiqueSidebarLeft]: classes?.includes(
          'critique-sidebar-left'
        ),
        [critiqueClasses.critiqueSidebarRight]: classes?.includes(
          'critique-sidebar-right'
        ),
        [critiqueClasses.critiqueSidebarLeftInner]: classes?.includes(
          'sidebar-right-inner'
        ),
        [critiqueClasses.critiqueSidebarRightInner]: classes?.includes(
          'sidebar-right-inner'
        ),
        [critiqueClasses.mobileTestimonials]: classes?.includes(
          'mobile-testimonials'
        ),
      })}
    >
      {items?.map((i) => (
        <ContentfulEntry key={i.contentful_id} {...i} />
      ))}
    </div>
  )
}
