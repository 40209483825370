import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { useCritique } from './api'
import CritiqueProfile1 from './_data/cp1.json'
import CritiqueProfile2 from './_data/cp2.json'
import CritiqueProfile3 from './_data/cp3.json'
import CritiqueProfile4 from './_data/cp4.json'
import CritiqueProfile5 from './_data/cp5.json'
import CritiqueProfile6 from './_data/cp6.json'
import CritiqueProfile7 from './_data/cp7.json'
import CritiqueProfile8 from './_data/cp8.json'
import CritiqueProfile9 from './_data/cp9.json'
import CritiqueProfile10 from './_data/cp10.json'
import CritiqueProfile15 from './_data/cp15.json'
import CritiqueProfile16 from './_data/cp16.json'
import CritiqueProfile17 from './_data/cp17.json'
import CritiqueProfile18 from './_data/cp18.json'
import CritiqueProfile19 from './_data/cp19.json'
import CritiqueProfile20 from './_data/cp20.json'
import CritiqueProfile21 from './_data/cp21.json'
import CritiqueProfile22 from './_data/cp22.json'
import CritiqueProfile23 from './_data/cp23.json'

import { useCritiqueStyles } from './styles'

const PROFILE_DEBUG_KEY = 'debug'

export const getCritiqueQueryParams = () => {
  if (typeof window === 'undefined') return { ssr: true }

  const queryParams = new URLSearchParams(window?.location?.search)

  return {
    at: queryParams.get('at') ?? '',
    cs: queryParams.get('cs') ?? '',
    [PROFILE_DEBUG_KEY]: ['true', ''].includes(
      queryParams.get(PROFILE_DEBUG_KEY) as string
    ),
    ssr: false,
  }
}

export const CRITIQUE_TYPES = {
  NORMAL: 2,
  TROUBLED: 6,
  NEARLYPERFECT: 7,
}

const CritiqueProfiles: Record<string, any> = {
  '1': CritiqueProfile1,
  '2': CritiqueProfile2,
  '3': CritiqueProfile3,
  '4': CritiqueProfile4,
  '5': CritiqueProfile5,
  '6': CritiqueProfile6,
  '7': CritiqueProfile7,
  '8': CritiqueProfile8,
  '9': CritiqueProfile9,
  '10': CritiqueProfile10,
  '15': CritiqueProfile15,
  '16': CritiqueProfile16,
  '17': CritiqueProfile17,
  '18': CritiqueProfile18,
  '19': CritiqueProfile19,
  '20': CritiqueProfile20,
  '21': CritiqueProfile21,
  '22': CritiqueProfile22,
  '23': CritiqueProfile23,
  live: null,
}

export const CritiqueDataContext = createContext(null) as any

const CritiqueProfileKeys = Object.keys(CritiqueProfiles)

export const CritiqueSelect: React.FC = () => {
  const [display, setDisplay] = useState(false)

  const { classes } = useCritiqueStyles()
  const data = useContext(CritiqueDataContext) as any

  useEffect(() => {
    const qParams = getCritiqueQueryParams()
    const shouldRender = !!qParams[PROFILE_DEBUG_KEY]
    setDisplay(shouldRender)
  }, [])

  if (!data && !data?.active?.length) return null

  const {
    active: [activeKey, activeCritique],
    set,
    keys,
  } = data

  if (!display) return null

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="16px"
      className={classes.floatingSelector}
    >
      <Select
        value={activeKey}
        onChange={(v) => {
          set(`${v.target.value}`)
        }}
        variant="standard"
        color="primary"
      >
        {keys.map((key: any) => (
          <MenuItem key={key} value={key}>
            Profile {key}
          </MenuItem>
        ))}
      </Select>
      <a href={activeCritique.critique_url} target="_blank" rel="noreferrer">
        <OpenInNew />
      </a>
    </Box>
  )
}

export type ICritiqueProfile = [
  string,
  Record<string, any>,
  {
    isPerfect: boolean
    isTroubled: boolean
    isNearlyPerfect: boolean
  }
]

export const CritiqueContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [at, setAt] = useState('')
  const [cs, setCs] = useState('')
  const [pageReady, setPageReady] = useState(false)

  useEffect(() => {
    const { at, cs, ssr } = getCritiqueQueryParams()
    if (ssr) return

    setAt(at ?? '')
    setCs(cs ?? '')
    setPageReady(true)
  }, [setAt, setCs])

  const [critiqueProfile, set] = React.useState<ICritiqueProfile>(() => {
    const profileData = CritiqueProfiles['1']

    return [
      '1',
      // profileData,
      {},
      {
        isPerfect: profileData?.critique_type_id === CRITIQUE_TYPES.NORMAL,
        isTroubled: profileData?.critique_type_id === CRITIQUE_TYPES.TROUBLED,
        isNearlyPerfect:
          profileData?.critique_type_id === CRITIQUE_TYPES.NEARLYPERFECT,
      },
    ]
  })

  const { data, isSuccess, isLoading } = useCritique({
    at,
    cs,
    pageReady,
  })

  const setCritiqueProfile = useCallback(
    (profile = '1') => {
      const profileData = profile === 'live' ? data : CritiqueProfiles[profile]

      if (profileData) {
        set([
          profile,
          profileData,
          {
            isPerfect: profileData?.critique_type_id === CRITIQUE_TYPES.NORMAL,
            isTroubled:
              profileData?.critique_type_id === CRITIQUE_TYPES.TROUBLED,
            isNearlyPerfect:
              profileData?.critique_type_id === CRITIQUE_TYPES.NEARLYPERFECT,
          },
        ])
      }
    },
    [data]
  )

  useEffect(() => {
    const { at, cs, ssr, ...params } = getCritiqueQueryParams()
    const isDebug = params[PROFILE_DEBUG_KEY]
    const hasProfile = at || cs

    isDebug && !hasProfile && setTimeout(setCritiqueProfile, 1500)
  }, [setCritiqueProfile])

  useEffect(() => {
    isSuccess && data && setCritiqueProfile('live')
  }, [isSuccess, data, setCritiqueProfile])

  return (
    <CritiqueDataContext.Provider
      value={{
        data,
        active: critiqueProfile,
        set: setCritiqueProfile,
        keys: CritiqueProfileKeys,
        request: {
          isLoading,
        },
      }}
    >
      {children}
    </CritiqueDataContext.Provider>
  )
}
