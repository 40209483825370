import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useCritiqueStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'

import useWindowScroll from 'react-use/lib/useWindowScroll'
import { SkeletonLoader } from './SkeletonLoader'
import { capitalize } from '../../utils/string'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import { Critique, useCritiqueThumbnail } from './api'

const CRITIQUEBG_IMAGE_CDN =
  'https://images.ctfassets.net/7thvzrs93dvf/5taaFmJuPtaDXt7MbJTfRc/f57e63b116da1fc5f3e94173096570fa/critique-bg-second.png'

const PLACEHOLDER_THUMBNAIL =
  'https://images.ctfassets.net/7thvzrs93dvf/BRolUUzfdqGcjni6Wcd4T/f159048bb102f590bd7a9c2ce50a09dc/resume-example-second.png'

const HeaderCritiquerName = () => {
  const theme = useTheme()
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const capitalizedBrandFlagship = capitalize(brand?.flagshipProduct)

  const isMobileXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileSm = useMediaQuery(theme.breakpoints.down('md'))

  const lastName = activeCritique?.critiquer?.critiquer_last_name

  const lastNameRender = isMobileXs ? `${lastName?.charAt(0)}.` : lastName

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="12px"
      justifyContent="flex-end"
    >
      <Typography
        variant="inherit"
        className={cx(
          classes.typographyHeaderMobile,
          classes.typographyHeader2,
          classes.bold
        )}
        align="center"
      >
        Your {capitalizedBrandFlagship} Review
      </Typography>
      <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
        <SkeletonLoader
          skeleton={{
            variant: 'circular',
            width: isMobileSm ? 32 : 24,
            height: isMobileSm ? 32 : 24,
            animation: 'wave',
          }}
          condition={!!activeCritique?.critiquer?.critique_image}
        >
          <img
            src={activeCritique?.critiquer?.critique_image}
            alt="Critiquer"
            className={classes.critiquerAvatarImage}
          />
        </SkeletonLoader>
        <Box display="flex" flexDirection="row" gap="3px">
          <Typography className={cx(classes.typographyBody, classes.neutral)}>
            Reviewed by
          </Typography>
          <SkeletonLoader
            skeleton={{
              variant: 'text',
              width: isMobileXs ? 60 : isMobileSm ? 100 : 60,
              height: 25,
              animation: 'wave',
            }}
            condition={!!activeCritique?.critiquer?.critiquer_first_name}
            className={[classes.skeletonAuthorHeader]}
          >
            <Typography className={cx(classes.typographyBody, classes.neutral)}>
              {activeCritique?.critiquer?.critiquer_first_name}
            </Typography>
          </SkeletonLoader>
          <SkeletonLoader
            skeleton={{
              variant: 'text',
              width: 30,
              height: 25,
              animation: 'wave',
            }}
            condition={!!activeCritique?.critiquer?.critiquer_last_name}
            className={[
              classes.skeletonAuthorHeader,
              classes.skeletonAuthorHeaderHideSm,
            ]}
          >
            <Typography className={cx(classes.typographyBody, classes.neutral)}>
              {lastNameRender}
            </Typography>
          </SkeletonLoader>
        </Box>
      </Box>
    </Box>
  )
}

const IndexBadgeItem: React.FC<{
  name: string
  active?: boolean
  section: number | string
  disabled?: boolean
  position?: number
  inViewParent?: boolean
  updateLastInView?: (index: number, scroll: number) => void
}> = ({
  name,
  section,
  disabled = false,
  position,
  inViewParent,
  updateLastInView,
}) => {
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const { classes, cx } = useCritiqueStyles()
  const selfRef = useRef<HTMLDivElement | null>(null)
  const targetRef = useRef<HTMLDivElement | null>(null)

  const inView = useIntersectionObserver(targetRef, {
    threshold: 0.2,
    freezeOnceVisible: false,
  })

  useEffect(() => {
    const sectionElement = document.getElementById(
      `critique-section-${section}`
    )

    if (sectionElement) {
      targetRef.current = sectionElement as HTMLDivElement
    }
  }, [activeCritique, section])

  const scrollToSection = () => {
    const sectionElement = document.getElementById(
      `critique-section-${section}`
    )

    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    if (inView && selfRef.current) {
      // scrollProgressBarToSelf()
      updateLastInView?.(position as number, selfRef.current?.offsetLeft)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <div ref={selfRef}>
      <Button
        onClick={scrollToSection}
        className={cx(classes.badgeItem, {
          [classes.active]: inViewParent,
        })}
        disabled={disabled}
        style={{ outline: 'none' }}
      >
        {name}
      </Button>
    </div>
  )
}

const SECTION_TITLE_MAP = {
  'design-structure-formatting': 'Format',
  'language-writing': 'Language',
}

const IndexBadges = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { classes } = useCritiqueStyles()
  const [lastInView, setLastInView] = useState<number | null>(null)
  const [arrowStatus, setArrowStatus] = useState([false, true])
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const scrollBadgeContainer = (direction: 'left' | 'right') => {
    const target = containerRef.current
    if (target) {
      const scrollAmount = direction === 'left' ? -100 : 100
      target.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const sections = useMemo(() => {
    if (!activeCritique?.full_sections) return ['...', '...', '...']

    return (
      activeCritique?.full_sections
        .filter(
          ({ sections }: { sections: string }) =>
            sections !== 'digital-readiness'
        )
        ?.map(({ sections }: { sections: string }) => {
          const mappedSection =
            SECTION_TITLE_MAP[sections as keyof typeof SECTION_TITLE_MAP]
          if (mappedSection) return mappedSection
          return null
        })
        .filter(Boolean) || []
    )
  }, [activeCritique])

  const captureScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget
    const percentageScrolled =
      (target.scrollLeft / (target.scrollWidth - target.offsetWidth)) * 100

    if (percentageScrolled === 0) {
      setArrowStatus([false, true])
    } else if (percentageScrolled === 100) {
      setArrowStatus([true, false])
    } else {
      setArrowStatus([true, true])
    }
  }

  const fixedSections = useMemo(() => {
    if (!activeCritique?.full_sections) return [['...', '']]
    return [
      ['ATS Scan', 'ats'],
      ['Skills', 'skills'],
      ['Next Steps', 'nextsteps'],
    ]
  }, [activeCritique?.full_sections])

  const updateLastInView = (index: number, scroll: number) => {
    const containerRefWidth = containerRef.current?.offsetWidth || 0

    if (scroll > 0 || (index === 0 && scroll === 0)) {
      setTimeout(() => {
        containerRef.current?.scrollTo({
          left: scroll - containerRefWidth / 3,
          behavior: 'smooth',
        })
      }, 250)
    }
    setLastInView(index)
  }

  const loading = !activeCritique?.full_sections?.length

  return (
    <div className={classes.badgeItemsHeaderContainer}>
      <Box
        display="flex"
        flexDirection="row"
        gap="10px"
        justifyContent="center"
        className={classes.badgeItemsArrows}
      >
        <Button
          onClick={() => scrollBadgeContainer('left')}
          variant="text"
          color="secondary"
          style={{
            pointerEvents: 'all',
            visibility: arrowStatus[0] ? 'visible' : 'hidden',
            outline: 'none',
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => scrollBadgeContainer('right')}
          variant="text"
          color="secondary"
          style={{
            pointerEvents: 'all',
            visibility: arrowStatus[1] ? 'visible' : 'hidden',
            outline: 'none',
          }}
        >
          <ChevronRight />
        </Button>
      </Box>
      <div
        id="badgeItemsContainer"
        className={classes.badgeItemsContainer}
        onScrollCapture={captureScroll}
        ref={containerRef}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap="10px"
          justifyContent="space-evenly"
          className={classes.badgeItems}
        >
          {sections &&
            sections?.map((item: string, index: number) => (
              <IndexBadgeItem
                key={index}
                name={item}
                section={index + 1}
                disabled={loading}
                position={index}
                updateLastInView={updateLastInView}
                inViewParent={lastInView === index}
              />
            ))}
          {fixedSections &&
            fixedSections?.map(([title, section], index: number) => (
              <IndexBadgeItem
                key={section}
                name={title}
                section={section}
                disabled={loading}
                position={index + sections.length}
                updateLastInView={updateLastInView}
                inViewParent={lastInView === index + sections.length}
              />
            ))}
        </Box>
      </div>
    </div>
  )
}

const clampScroll = (scroll: number) => Math.min(Math.max(scroll * 100, 0), 100)

const ScrollProgress = () => {
  const { classes } = useCritiqueStyles()
  const [windowHeight, setWindowHeight] = useState(0)
  const previousHeight = useRef(windowHeight)
  const { y: windowY } = useWindowScroll()

  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  useEffect(() => {
    if (document) {
      const target = document.getElementsByClassName(
        'critique-content-container'
      )[0]
      const targetScrollHeight = target?.scrollHeight || 0
      const faqSection = document.getElementById('critique-faq-section')

      const finalHeight =
        targetScrollHeight - (faqSection?.scrollHeight || 0) * 2.2

      target && setWindowHeight(finalHeight)
    }
  }, [activeCritique])

  const progress = useMemo(() => {
    if (windowHeight <= 0) return 0
    const currentProgress = (windowY / windowHeight) * 100

    if (currentProgress < previousHeight.current)
      return Math.min(previousHeight.current, 100)
    previousHeight.current = currentProgress
    return clampScroll(windowY / windowHeight)
  }, [windowY, windowHeight, previousHeight])

  return (
    <LinearProgress
      classes={{ colorPrimary: classes.progressBar }}
      variant="determinate"
      value={progress}
    />
  )
}

export const CritiqueHeaderV2 = () => {
  const { classes, cx } = useCritiqueStyles()
  const theme = useTheme()

  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const { data, isFetched } = useCritiqueThumbnail(activeCritique as Critique)

  const critiqueThumbnailURL = useMemo(() => {
    return data ?? PLACEHOLDER_THUMBNAIL
  }, [data])

  const isMobileXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileSm = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      {isPerfect && (
        <Box className={cx(classes.scrollProgressContainer, classes.topScroll)}>
          <IndexBadges />
          <ScrollProgress />
        </Box>
      )}
      <Box
        display="flex"
        className={classes.critiqueHeaderV2Container}
        style={{
          overflow: 'hidden',
          backgroundImage: `url(${CRITIQUEBG_IMAGE_CDN})`,
          backgroundSize: '70% 100%',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <HeaderCritiquerName />
        <SkeletonLoader
          skeleton={{
            variant: 'text',
            width: isMobileXs ? '100%' : isMobileSm ? 280 : 200,
            height: 300,
            animation: 'wave',
          }}
          condition={isFetched}
          style={{
            position: 'relative',
            top: isMobileXs ? -50 : isMobileSm ? -50 : -70,
            minHeight: isMobileXs ? 250 : 250,
          }}
          className={[classes.resumeExampleContainerSkeleton]}
        >
          <Box
            className={classes.resumeExampleContainer}
            style={{
              position: 'relative',
              backgroundImage: `url(${critiqueThumbnailURL})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'white',
              borderRadius: '8px',
            }}
          />
        </SkeletonLoader>
      </Box>
      {isPerfect && (
        <Box
          className={cx(classes.scrollProgressContainer, classes.bottomScroll)}
        >
          <IndexBadges />
          <ScrollProgress />
        </Box>
      )}
    </>
  )
}
