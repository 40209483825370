import React, { FC } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import { useCritiqueStyles } from '../styles'
import useBrand from '../../../hooks/useBrand'
import { interpolateString } from '../../../utils/interpolateString'

export type TextProps = Pick<TypographyProps, 'children'> & {
  fontWeight?: 'bold' | 'bolder' | 'lighter' | 'normal' | 'medium'
  variant: TypographyProps['variant'] | 'subhead' | 'medium'
}

export const Text: FC<TextProps> = (props) => {
  const brand = useBrand()
  const { classes, cx } = useCritiqueStyles()
  const { children, variant, fontWeight } = props

  return (
    <Typography
      variant="inherit"
      style={{ fontWeight, display: 'block' }}
      className={cx({
        [classes.typographySubhead]: variant === 'subhead',
        [classes.typographyBody]: variant === 'body1',
        [classes.typographyBody2]: variant === 'body2',
        [classes.bold]: fontWeight === 'medium',
      })}
    >
      {interpolateString(children as string, {
        ...brand,
      })}
    </Typography>
  )
}
