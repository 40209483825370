import React from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { ContentString } from './content'

const letterHeader = ContentString.letterHeader

interface CritiqueExportSignatureProps {
  critiquer: CritiqueSignatureCritiquer
}

interface CritiqueSignatureCritiquer {
  name?: string
  email?: string
  role?: string
  signature?: string
  picture?: string
}

export const CritiqueExportSignature: React.FC<
  CritiqueExportSignatureProps
> = ({ critiquer }) => {
  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <ExpertSignature {...critiquer} />
    </Box>
  )
}

const ExpertSignature: React.FC<CritiqueSignatureCritiquer> = ({
  email,
  name,
  role,
  signature,
  picture,
}) => {
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {picture && <img src={picture} alt="Author" width="48px" height="48px" />}
      <Box display="flex" flexDirection="column">
        {name && (
          <Typography variant="inherit" className={classes.typographyBody}>
            {name || letterHeader.reviewer.name}
          </Typography>
        )}
        {role && (
          <Typography
            variant="inherit"
            className={cx(classes.typographyBody, classes.neutral)}
          >
            {role || letterHeader.reviewer.role}
          </Typography>
        )}
        {signature && (
          // Render the Signature HTML:
          <div
            dangerouslySetInnerHTML={{
              __html: signature,
            }}
          />
          // <Typography className={cx(classes.typographyBody, classes.neutral)}>
          //   {signature}
          // </Typography>
        )}
        {email && (
          <a
            className={cx(classes.typographyBody, classes.link)}
            href={`mailto:${email || letterHeader.reviewer.email}`}
          >
            {email || letterHeader.reviewer.email}
          </a>
        )}
      </Box>
    </Box>
  )
}
